import React, { useRef, createRef, useEffect } from 'react';
import gsap from 'gsap';
import { SquareWrapper, Square } from './portofolioSquareAnimation.styled';

const PortfolioSquareAnimation = () => {
  const squareRef = useRef([...Array(30)].map(() => createRef()));

  const tl = gsap.timeline();

  useEffect(() => {
    tl.to(squareRef.current[0].current, 0.15, {
      opacity: 0.2,
      backgroundColor: '#942985',
    })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[8].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[14].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[15].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[21].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[5].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[4].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[3].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[2].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[1].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[0].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[24].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 1,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[25].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.8,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[26].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.6,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[27].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.4,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[28].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0.2,
        backgroundColor: '#942985',
      })
      .to(squareRef.current[29].current, 0.05, {
        opacity: 0,
        backgroundColor: '#942985',
      });
  });
  return (
    <SquareWrapper>
      <Square ref={squareRef.current[0]} />
      <Square ref={squareRef.current[1]} />
      <Square ref={squareRef.current[2]} />
      <Square ref={squareRef.current[3]} />
      <Square ref={squareRef.current[4]} />
      <Square ref={squareRef.current[5]} />
      <Square ref={squareRef.current[6]} />
      <Square ref={squareRef.current[7]} />
      <Square ref={squareRef.current[8]} />
      <Square ref={squareRef.current[9]} />
      <Square ref={squareRef.current[10]} />
      <Square ref={squareRef.current[11]} />
      <Square ref={squareRef.current[12]} />
      <Square ref={squareRef.current[13]} />
      <Square ref={squareRef.current[14]} />
      <Square ref={squareRef.current[15]} />
      <Square ref={squareRef.current[16]} />
      <Square ref={squareRef.current[17]} />
      <Square ref={squareRef.current[18]} />
      <Square ref={squareRef.current[19]} />
      <Square ref={squareRef.current[20]} />
      <Square ref={squareRef.current[21]} />
      <Square ref={squareRef.current[22]} />
      <Square ref={squareRef.current[23]} />
      <Square ref={squareRef.current[24]} />
      <Square ref={squareRef.current[25]} />
      <Square ref={squareRef.current[26]} />
      <Square ref={squareRef.current[27]} />
      <Square ref={squareRef.current[28]} />
      <Square ref={squareRef.current[29]} />
    </SquareWrapper>
  );
};

export default PortfolioSquareAnimation;
