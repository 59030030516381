import styled, { keyframes } from 'styled-components';
import Image from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Modal from 'react-modal';

export const BackgroundWrapper = styled(BackgroundImage)`
  background-size: cover;
  width: 100%;
  height: initial;

  @media (min-width: 850px) {
    height: 50%;
    display: flex;
    justify-content: flex-start;
  }

  @media (min-width: 1100px) {
    @media (min-height: 600px) {
      height: 435px !important;
    }
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 850px) {
    width: calc(100% - 50px);
    margin-left: 50px;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;

  @media (min-width: 850px) {
    margin-top: 10px;
    margin-right: 50px;
    width: 600px;
    height: 370px;
    padding: 0 0;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: #eeeae8;
      background-clip: content-box;
      border-radius: 15px;
    }

    ::-webkit-scrollbar-thumb {
      background: #922a84;
      border-radius: 15px;
      height: 1rem;
    }
  }

  @media (min-width: 900px) {
    width: 648px;
    height: 380px;
  }
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const StyledImageMobile = styled(Image)`
  width: 30%;
  margin: 1%;
  height: auto;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const ImageLink = styled.button`
  width: 30%;
  margin: 1%;
  height: auto;
  border: none;
  background-color: transparent;
  display: none;

  @media (min-width: 850px) {
    display: block;
  }
`;

export const TextWrapper = styled.p`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.white};

  @media (min-width: 850px) {
    position: absolute;
    width: 280px;
    height: 20px;
    transform: rotate(270deg);
    top: 130px;
    right: -105px;
  }
`;

export const TextWord = styled.span`
  display: ${props => (props.isNotVisible ? 'none' : 'inline-block')};
`;

export const LinkWrapper = styled.a`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.s};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 850px) {
    font-size: ${({ theme }) => theme.font.xs};
    position: absolute;
    width: 280px;
    height: 20px;
    top: 370px;
    right: -115px;
  }
`;

export const Scale = keyframes`
  0% {
    transform: scale(1);
    transform-origin: 'center center';
  }

  10% {
    transform: scale(1.15);
    transform-origin: 'center center';
  }

  20% {
    transform: scale(1);
    transform-origin: 'center center';
  }
`;

export const SocialImage = styled(Image)`
  width: 37px;
  height: 23px;
  animation: ${Scale} 5s ease-in-out forwards infinite;
`;

export const AnimationSvg = styled.div`
  display: none;
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;

  @media (min-width: 1050px) {
    display: flex;
    width: 200px;
  }

  @media (min-width: 1100px) {
    display: flex;
    width: 250px;
  }

  @media (min-width: 1150px) {
    display: flex;
    width: 320px;
  }

  @media (min-width: 1230px) {
    display: flex;
    width: 400px;
  }

  @media (min-width: 1300px) {
    display: flex;
    width: 500px;
  }

  @media (min-width: 1350px) {
    display: none;
  }

  @media (min-width: 1450px) {
    display: flex;
    width: 200px;
  }

  @media (min-width: 1500px) {
    width: 250px;
  }

  @media (min-width: 1550px) {
    display: flex;
    width: 300px;
    height: 300px;
    left: 0;
  }

  @media (min-width: 1650px) {
    width: 400px;
    height: 400px;
  }

  @media (min-width: 1750px) {
    left: 50px;
  }

  @media (min-width: 1850px) {
    left: 100px;
  }
`;

export const ModalImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  position: relative;
  z-index: 3;

  ::-webkit-scrollbar {
    width: 1rem;
  }

  ::-webkit-scrollbar-track {
    background: #eeeae8;
    background-clip: content-box;
    border-radius: 15px;
  }

  ::-webkit-scrollbar-thumb {
    background: #922a84;
    border-radius: 15px;
    height: 1rem;
  }
`;

export const ModalImage = styled(Image)`
  width: 30%;
  margin: 1%;
  height: auto;
`;

export const ModalButton = styled.button`
  border: 0;
  width: 20px;
  height: 20px;
  font-size: ${({ theme }) => theme.font.xl};
  color: ${({ theme }) => theme.colors.grey};
  background-color: transparent;
  position: absolute;
  top: 65px;
  left: 65px;
  z-index: 4;
  cursor: pointer;
`;

export const StyledModal = styled(Modal)`
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  background-color: ${({ theme }) => theme.colors.white};
  margin: 50px;
  display: none;

  @media (min-width: 850px) {
    display: block;
  }
`;
