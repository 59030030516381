import { graphql, useStaticQuery } from 'gatsby';

const InstagramFetch = () => {
  const data = useStaticQuery(graphql`
    query {
      allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 12) {
        nodes {
          id
          username
          timestamp
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return data.allInstaNode.nodes.map(node => ({
    ...node.localFile.childImageSharp,
    id: node.id,
    username: node.username,
    timestamp: node.timestamp,
  }));
};

export default InstagramFetch;
