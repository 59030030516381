import styled from 'styled-components';

export const Svg = styled.svg`
  width: 100%;
  height: 100%;

  .st0 {
    fill: #eeeae8;
  }
  .st1 {
    enable-background: new;
  }
  .st2 {
    fill: #ddebf1;
  }
  .st3 {
    fill: #a8c6da;
  }
  .st4 {
    fill: #ffffff;
  }
  .st5 {
    opacity: 0.15;
    fill: #243551;
  }
  .st6 {
    fill: #d36e6e;
  }
  .st7 {
    fill: #f7b7a9;
  }
  .st8 {
    fill: #243551;
  }
  .st9 {
    fill: #0081a7;
  }
  .st10 {
    opacity: 0.15;
  }
  .st11 {
    fill: #b77b65;
  }
  .st12 {
    fill: #005163;
  }
  .st13 {
    fill: #ff8484;
  }
  .st14 {
    fill: #eaf3f5;
  }
  .st15 {
    fill: #84c3d7;
  }
  .st16 {
    fill: #69b5cf;
  }
  .st17 {
    opacity: 0.15;
    clip-path: url(#SVGID_2_);
  }
  .st18 {
    clip-path: url(#SVGID_2_);
  }
  .st19 {
    fill: #c1deea;
  }
  .st20 {
    fill: #412928;
  }
  .st21 {
    fill: #f8c4b8;
  }
  .st22 {
    fill: #45393f;
  }
  .st23 {
    fill: #b7cace;
  }
  .st24 {
    fill: #1b283d;
  }
  .st25 {
    opacity: 0.7;
    fill: url(#SVGID_3_);
  }
  .st26 {
    opacity: 0.5;
    fill: #243551;
  }
  .st27 {
    fill: #4d8997;
  }
  .st28 {
    fill: #d8e9ec;
  }
  .st29 {
    opacity: 0.95;
  }
  .st30 {
    fill: #8fc1d4;
  }
  .st31 {
    fill: #e7f1f4;
  }
  .st32 {
    opacity: 0.1;
    fill: #243551;
  }
  .st33 {
    opacity: 0.7;
    enable-background: new;
  }
  .st34 {
    opacity: 0.6;
    fill: #ffffff;
  }
  .st35 {
    fill: #ccdaea;
  }
  .st36 {
    fill: #dae4ef;
  }
  .st37 {
    fill: #f2f6fa;
  }
  .st38 {
    opacity: 0.4;
    fill: #ffffff;
  }
  .st39 {
    fill: #0d6189;
  }
  .st40 {
    opacity: 0.2;
    fill: #243551;
  }
  .st41 {
    fill: #81524f;
  }
  .st42 {
    fill: #b7d1d8;
  }
  .st43 {
    fill: #b2cada;
  }
  .st44 {
    fill: #69b4ce;
  }
  .st45 {
    fill: #83c2d6;
  }
  .st46 {
    fill: #243551;
  }
  .st47 {
    fill: #df9877;
  }
  .st50 {
    fill: #d28f71;
  }
  .st51 {
    fill: #333333;
  }
  .st52 {
    fill: #666666;
  }
  .st53 {
    fill: #cfdfe5;
  }
  .st54 {
    enable-background: new 0 0 456.8 405.7;
  }
  .st55 {
    overflow: visible;
  }
  .st56 {
    stop-color: #ffffff;
  }
  .st57 {
    stop-color: #aac4d8;
  }
`;
