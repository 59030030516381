import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BehanceFetch from 'components/behanceFetch/behanceFetch';
import PortfolioSvg from 'components/portfolioSvg/portfolioSvg';
import Modal from 'react-modal';
import {
  BackgroundWrapper,
  SectionWrapper,
  ImageWrapper,
  StyledImage,
  StyledImageMobile,
  ImageLink,
  TextWrapper,
  TextWord,
  LinkWrapper,
  SocialImage,
  AnimationSvg,
  StyledModal,
  ModalImageWrapper,
  ModalImage,
  ModalButton,
} from './portfolioBehance.styled';

const PortfolioBehance = () => {
  const behancePhotos = BehanceFetch();

  Modal.setAppElement('#___gatsby');

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    if (window !== undefined) {
      window.location.reload();
    }
  }

  const { background, image } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "background.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image: file(relativePath: { eq: "behance_white.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundWrapper Tag="div" fluid={background.sharp.fluid}>
      <SectionWrapper>
        <TextWrapper>
          <TextWord>Przykładowe&nbsp;</TextWord>
          <TextWord>Realizacje&nbsp;</TextWord>
          <TextWord>Behance&nbsp;</TextWord>
        </TextWrapper>
        <ImageWrapper>
          {behancePhotos.map(photo => (
            <>
              <ImageLink onClick={openModal} key={photo.id}>
                <StyledImage fluid={photo.fluid} />
              </ImageLink>
              <StyledImageMobile fluid={photo.fluid} key={photo.id} />
            </>
          ))}
        </ImageWrapper>
        <LinkWrapper>
          <TextWord isNotVisible>Zobacz&nbsp;</TextWord>
          <TextWord isNotVisible>pozostałe&nbsp;</TextWord>
          <TextWord isNotVisible>grafiki!&nbsp;</TextWord>
          <SocialImage fluid={image.sharp.fluid} />
        </LinkWrapper>
        <AnimationSvg>
          <PortfolioSvg />
        </AnimationSvg>
        <StyledModal isOpen={modalIsOpen} onRequestClose={closeModal}>
          <ModalImageWrapper>
            {behancePhotos.map(photo => (
              <ModalImage fluid={photo.fluid} key={photo.id} />
            ))}
          </ModalImageWrapper>
          <ModalButton onClick={closeModal} type="button">
            &times;
          </ModalButton>
        </StyledModal>
      </SectionWrapper>
    </BackgroundWrapper>
  );
};

export default PortfolioBehance;
