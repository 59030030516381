import { graphql, useStaticQuery } from 'gatsby';

const BehanceFetch = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile(filter: { relativeDirectory: { eq: "behance" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return data.allFile.edges.map(node => ({
    ...node.node.childImageSharp,
    id: node.node.id,
    name: node.node.name,
  }));
};

export default BehanceFetch;
