import React, { createRef, useEffect } from 'react';
import gsap from 'gsap';
import { Svg } from './portfolioSvg.styled';

const PortfolioSvg = () => {
  const firstFlatRef = createRef();
  const secondFlatRef = createRef();

  const firstPeopleRef = createRef();
  const secondPeopleRef = createRef();

  const tabletRef = createRef();
  const onlyTabletRef = createRef();

  const wifiRef = createRef();

  const tl = gsap.timeline();

  const tabletTl = gsap.timeline({
    repeat: -1,
    yoyo: true,
  });

  const wifiTl = gsap.timeline({
    repeat: -1,
  });

  function onTabletFinish() {
    tabletTl.play();
  }

  function onWifiFinish() {
    wifiTl.play();
  }

  useEffect(() => {
    tabletTl.to(onlyTabletRef.current, 2.5, { y: -5 });
    wifiTl.staggerFrom(wifiRef.current.children, 1, { opacity: 0 }, 0.35);
    tl.set(
      [
        firstFlatRef.current,
        secondFlatRef.current,
        firstPeopleRef.current,
        tabletRef.current,
        secondPeopleRef.current,
      ],
      { opacity: 0 }
    )
      .to(firstFlatRef.current, 0.45, {
        opacity: 1,
      })
      .to(secondFlatRef.current, 0.45, {
        opacity: 1,
      })
      .set(firstPeopleRef.current, { opacity: 1 })
      .staggerFrom(firstPeopleRef.current.children, 0.45, { opacity: 0 }, 0.15)
      .set(tabletRef.current, { opacity: 1, onTabletFinish })
      .staggerFrom(tabletRef.current.children, 0.45, { opacity: 0 }, 0.15)
      .set(secondPeopleRef.current, { opacity: 1, onWifiFinish })
      .staggerFrom(
        secondPeopleRef.current.children,
        0.45,
        { opacity: 0 },
        0.15
      );
  });

  return (
    <Svg
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 456.8 405.7"
      classNameName="st54"
    >
      <g id="Animacja_1">
        <g>
          <path
            className="st0"
            ref={firstFlatRef}
            d="M411.1,259.8l-126.5-73.5c-2.5-1.4-6.5-1.4-9,0l-211.3,122c-2.5,1.4-2.5,3.8,0,5.2L190.7,387
			c2.5,1.4,6.5,1.4,9,0L411,265C413.5,263.5,413.6,261.2,411.1,259.8z"
          />
        </g>
        <g>
          <g className="st1">
            <g>
              <path
                className="st2"
                ref={secondFlatRef}
                d="M429.9,206l-142.2-83.4c-2.7-1.5-7-1.5-9.7,0L51.8,253.2c-2.7,1.5-2.7,4.1,0,5.6L194,342.2
					c2.7,1.5,7,1.5,9.7,0l226.2-130.6C432.5,210.1,432.5,207.6,429.9,206z"
              />
            </g>
            <path
              className="st3"
              d="M429.8,211.6L203.6,342.2c-2.7,1.5-7,1.5-9.7,0L51.8,258.8c-1.3-0.8-2-1.8-2-2.8l0,7c0,1,0.7,2,2,2.8
				L194,349.3c2.7,1.5,7,1.5,9.7,0l226.2-130.6c1.3-0.8,2-1.8,2-2.8l0-7C431.9,209.8,431.2,210.9,429.8,211.6z"
            />
          </g>
        </g>
      </g>
      <g id="Animacja_2" ref={firstPeopleRef}>
        <g>
          <path
            className="st4"
            d="M184.6,192.6l-17.3,10c-2.2,1.2-3.9,4.1-3.9,6.4l0.1,19l3.5-7l17.7-10.2c2.2-1.2,3.9-4.1,3.9-6.4l0-9.8
			C188.5,192.3,186.8,191.4,184.6,192.6z"
          />
        </g>
        <g>
          <path
            className="st5"
            d="M163.6,265.4c-4.6-1.2-10.1-0.7-14.9-0.9c-4.9-0.2-12.3-0.6-15.7,3.6c-0.3,0.4-0.4,0.9-0.8,1.2
			c-0.8,0.6-2.9,1-3.9,1.4c-0.3,0.1-2,0.4-2.1,0.8c-0.1,0.5,0.9,1,0.9,1.2c1.6,1.8,4.6,1.9,6.8,2.3c3.2,0.5,6.6,0.7,9.8,0.9
			c3.2,0.1,6.3-0.6,9.5-1c3.3-0.4,7.2-0.2,10-2.1c1.6-1,2.3-2.7,2.7-4.5C166.4,266.4,165.6,265.9,163.6,265.4z"
          />
          <g>
            <path
              className="st6"
              d="M136.7,264l-0.5,3.7l11.7-3.5l7.7,5.7l7.2-3.8c0,0-12.7-6.9-14.5-7C146.4,259,136.7,264,136.7,264z"
            />
            <g>
              <path
                className="st7"
                d="M164,256l-0.4,10.6c0,0,3.7-6.8,3.7-8.7c-0.3-7.9-1.8-13.5-1.8-13.5L164,256z"
              />
              <path
                className="st8"
                d="M165.5,244.4L164,256l-0.4,10.6c0,0,0.2-0.3,0.5-0.9c-0.4-3.6,0.7-7.5,3.1-10.1
					C166.7,249,165.5,244.4,165.5,244.4z"
              />
              <path
                className="st9"
                d="M164.1,253.9c1.1,0.2,2.3,0,3.4-1.2c-0.9-5.2-2.1-8.3-2.1-8.3L164.1,253.9z"
              />
              <path
                className="st9"
                d="M162.3,241.9c0,0-2.1-0.8-4.8,0.2c-3.7,1.3-4.9,3.5-4.9,3.5s0.8,12.6,0.5,15.9c-0.2,1.9-1.7,2.7-1.7,2.7
					s1.8,5.2,4.8,5.1c3.4-0.1,8.5-1.8,8.5-1.8s1.6-7.2,1.7-9.1c0.1-1.9-0.4-11.6-0.9-13.7C165,242.4,162.3,241.9,162.3,241.9z"
              />
              <path
                className="st10"
                d="M165.1,243.7c-4.7,4.3-5.9,9.8-6.4,16.2c-0.3,3.3-0.5,6.4-1.2,9.3c3.3-0.4,7.2-1.7,7.2-1.7
					s1.6-7.2,1.7-9.1c0.1-1.9-0.4-11.6-0.9-13.7C165.4,244.3,165.3,244,165.1,243.7z"
              />
              <path
                className="st7"
                d="M156.4,238.2l1.1,3.9c0.1,0.9,4.2,0.9,4.2-0.5l-0.2-3.6L156.4,238.2z"
              />
              <path
                className="st7"
                d="M155.1,238.1c-0.1,2.2,1.5,3.1,3.7,3.2c0,0,4.1-1.1,4.2-3.2l0.6-3.9l-8.3-1.6L155.1,238.1z"
              />
              <path
                className="st11"
                d="M157.2,229.8c0,0-3,0.7-3.2,1c0,0,0.1,2.6,1.3,3.4c0,0,1.1,0.3,1.1,1.9c-0.1,1.7,1.1,3.1,2.4,4.2
					c1,0.8,3.4-0.1,3.4-0.1s3.2-3.1,2-8C163.5,229.2,158.5,229.3,157.2,229.8z"
              />
              <path
                className="st7"
                d="M156,236.2l-0.1,1.8c0.8,0.4,1.5-0.6,1.4-1.6C157.2,235.2,156,236.2,156,236.2z"
              />
            </g>
            <path
              className="st8"
              d="M128.7,264.9c-1.2-0.2-3.8-0.6-3.6,0.9c0.2,1.5,0.8,1.7,5.4,3.2c4.6,1.5,2.6-4.1,2.6-4.1
				S129.9,265.1,128.7,264.9z"
            />
            <path
              className="st12"
              d="M129.9,266.2c-0.8-0.3-1.5-0.7-1.9-1c-0.9-0.5-2.5-0.5-2.6,0.4c-0.2,1.1,0.6,1.6,5.2,3.1
				c4.6,1.5,2.4-2.4,2.4-2.4S131,266.6,129.9,266.2z"
            />
            <path
              className="st8"
              d="M126.9,268.5c-0.5-0.1-1.7-0.6-2.7,0c-1,0.6-1.1,2.5,0.7,3.2c1.8,0.7,5.4,2.3,6.1,2.2
				c0.7-0.1,2.2-0.4,2.2-1.6s0.2-2.2,0.2-2.2l-3-1.2C130.5,268.9,127.4,268.6,126.9,268.5z"
            />
            <path
              className="st13"
              d="M157.2,266.6c-1.4-0.2-4.8-3.2-4.8-3.2l-4.5,6.1c0,0,4,4.4,6.9,4.4c2.9,0,6.6-0.5,7.9-2.1
				c2.8-3.6,2.5-6,2.5-6S159.2,266.9,157.2,266.6z"
            />
            <path
              className="st10"
              d="M158.8,266.6c0.1,3.5-2.4,6.4-5.9,6.7c0.6,0.3,1.3,0.5,1.8,0.5c2.9,0,6.6-0.5,7.9-2.1c2.8-3.6,2.5-6,2.5-6
				S161.3,266.5,158.8,266.6z"
            />
            <path
              className="st13"
              d="M142.1,256.3c-0.9-0.6-3-0.6-3.9,0c0,0,0,0-0.1,0c-1.3,0.9-1.5,1.7-0.5,2.9l12.3,12.2l5.2-4.8l-2.7-3.2
				L142.1,256.3z"
            />
            <path
              className="st13"
              d="M136.9,257.9c-0.9,2.1-6.7,10.9-6.7,10.9s-0.9,1.1,0.4,2.2c1.3,1.1,2.7,0.2,2.7,0.2s6.2-4.5,6.7-5.6
				c1.5-3.2,1.9-7.3,1.9-7.3l-2.5-2.4C139.4,255.9,137.7,255.8,136.9,257.9z"
            />
            <path
              className="st7"
              d="M156,243.3c-3,0.7-3.2,3.2-3.2,3.2l-3,8.5l-7.6-0.8l-2.7-0.2l-2.3,0.8l-1,1.9c0,0-0.1-0.1,0,0.3
				c0.1,0.4,0.5,0.4,0.5,0.4l0.7-0.5l1.6-0.2c1.8,0.4,3.4-0.3,3.4-0.3s2.7,1.6,9.1,2.2c0.6,0.1,1.1-0.2,1.3-0.7l3.3-8.6
				C156.2,249.2,159,242.6,156,243.3z"
            />
            <path
              className="st5"
              d="M156,243.3c-3,0.7-3.2,3.2-3.2,3.2l-0.1,0.4c1.3,0.4,2.4,1.6,1.9,3.2c-0.5,1.9-0.9,3.8-2.5,5.1
				c-1.6,1.3-3.6,1.4-5.5,1.2c-3.4-0.4-7-0.6-10.2,0.7c0.2,0.3,0.5,0.3,0.5,0.3l0.7-0.5l1.6-0.2c1.8,0.4,3.4-0.3,3.4-0.3
				s2.7,1.6,9.1,2.2c0.6,0.1,1.1-0.2,1.3-0.7l3.3-8.6C156.2,249.2,159,242.6,156,243.3z"
            />
          </g>
          <path
            className="st9"
            d="M156,243.3c-3,0.7-3.7,3-3.7,3l-2.1,6.3c1.4,1.1,3.6,1.9,5.3,2.1l0.8-5.4C156.2,249.2,159,242.6,156,243.3z"
          />
        </g>
        <g>
          <g>
            <path
              className="st5"
              d="M235.8,219.9c-11.7-2.5-24.7,1.1-28.9,8.1c-1.8,3-1.7,6-0.2,8.8c1.8,4.7,4.1,19-29.7,24.4
				c-6.2,0.9-11.6,3.6-13.9,7.4c-3.7,6.1,1.6,12.9,11.9,15.1c3.9,0.8,38.4-0.1,57.3-14.7c18.4-14.3,19.8-25.8,18.3-36.2
				C250.9,227.2,245.2,221.9,235.8,219.9z"
            />
          </g>
          <g>
            <g className="st1">
              <g>
                <path
                  className="st4"
                  d="M250.7,211.9l0,16.8c0-0.1,0-0.2,0-0.4l0-16.8C250.7,211.6,250.7,211.7,250.7,211.9z"
                />
              </g>
              <path
                className="st14"
                d="M206.7,215.8c-0.8-1.4-1.2-2.8-1.2-4.2l0,16.8c0,1.5,0.4,2.9,1.2,4.2c0.4,1.1,0.9,2.8,0.9,4.7l0-16.8
					C207.6,218.6,207.2,217,206.7,215.8z"
              />
              <g>
                <path
                  className="st15"
                  d="M235.8,199c-11.7-2.5-24.7,1.1-28.9,8.1c-1.8,3-1.7,6-0.2,8.8c1.8,4.7,4.1,19-29.7,24.4
						c-6.2,0.9-11.6,3.6-13.9,7.4c-3.7,6.1,1.6,12.9,11.9,15.1c3.9,0.8,7.9,0.9,11.6,0.3c1.3,0,26.8-0.4,45.7-15.1
						c18.4-14.3,19.8-25.8,18.3-36.2C250.9,206.3,245.2,201,235.8,199z"
                />
              </g>
              <path
                className="st16"
                d="M232.4,248c-18.9,14.7-53.5,15.6-57.3,14.7c-8.1-1.7-13.1-6.2-13.1-11l0,16.8c0,4.8,5,9.3,13.1,11
					c3.9,0.8,38.4-0.1,57.3-14.7c15.1-11.7,18.8-21.6,18.7-30.5l0-16.8C251.2,226.4,247.5,236.3,232.4,248z"
              />
            </g>
          </g>
        </g>
        <path
          className="st10"
          d="M234.6,224.5c-4.6-0.9-7.2,0.8-9.6,4.3c-2.2,3.1-5.1,4-8.7,5.1c-6.5,2-12.5,4.1-18.3,7.6
		c-2.6,1.5-5.1,2.9-7.8,4.2c-2.5,1.2-5.3,2.2-7.5,4c-0.9,0.7-1.6,1.5-2.6,2.1c-1.6,1.1-2.7,1.3-4.4,1.9c-0.7,0.2-1.9,0.7-1.6,1.9
		c0.7,1.5,5.1,1.2,6.5,1.2c2.5,0,3.7-1.5,5.6-2.8c1.3-0.8,2.6-1.6,3.8-2.5c1-0.7,2.4-2.2,3.5-2.5c3.1-0.8,1.1,5.8,0.3,7
		c-1.1,1.8-2.6,2.5-4.5,3.2c-1,0.4-2.4,0.6-2.3,2.1c2.4,0,4.8-0.3,7-1.1c2-0.7,4.9-1,6.2-2.8c2.2-3-0.7-10.9,4.1-12.1
		c1.4-0.3,3,0,4.4,0.1c1.8,0.1,3.7,0,5.5,0c2,0,4.5,0.2,6.5-0.2c2.2-0.5,5-1.9,6.9-3c2.2-1.3,3.8-3.5,5.8-5.2c1.3-1.1,2.9-1.5,4.6-2
		c1.5-0.4,4-0.9,5-2.2C246,228.6,237.2,225,234.6,224.5z"
        />
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M193.4,235.6c0,0,16.6-4.5,14.2-15.1l25.7-8.1l-12.8-49.2l-20.6,0.6c0,0-20.1,9.3-19.5,10.2
				c0.5,1,7.3,30.4,7.3,30.4l-1.8,15.3l-8.1,14.6L193.4,235.6z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use className="st55" />
          </clipPath>
          <path
            className="st17"
            d="M218.7,205.2c-3.1,0.1-5.9,1.1-7.9,3.5c-1.5,1.8-3.4,3.5-4.5,5.5c5.6,0,15.3-0.7,18.2-3.6
			c1.1-1.1,1.8-4.8,0.8-6.2C224,202.7,220.4,205.2,218.7,205.2z"
          />
          <g className="st18">
            <path
              className="st11"
              d="M203.6,181.8c-2.7-0.2-10.7-1.9-10.7-1.9l-4.4-1.4l-5.4-0.5c0,0-0.1,0.3,0.4,0.5c0.5,0.2,1.9,0.5,1.9,0.5
				s0.2,2.5,0.6,2.5c0.4,0.1,1.8,0.6,1.8,0.6l-0.1-0.4c0,0,2.1,0.3,3.1-0.2l0.1-0.1c1.4,0.7,9.6,3.6,11.8,4.4
				c0.3,0.1,0.7,0.2,1.1,0.1l12.3-1.5l0.5-5.6C216.6,178.8,207.5,182.1,203.6,181.8z"
            />
            <path
              className="st10"
              d="M191.6,181.6c-0.3-0.1-0.7-0.2-1-0.4c0,0,0,0.1,0.1,0.1c0.4,0.4,9.7,3.7,12,4.5c0.3,0.1,0.7,0.2,1.1,0.1
				l12.3-1.5l0.3-3.1c-2.6,1.3-5.2,2.5-8,2.9C203.1,185.1,196.5,183.1,191.6,181.6z"
            />
            <path
              className="st9"
              d="M219.7,177.6c-1.7-1.2-4.9,0.6-6.7,5.1c-0.6,1.5-1.7,2.3-1.7,4c0,1.5,1.7,3.2,1.7,3.2s1.3,4.9,3.5,10.6
				c0.7,1.8-2.8,4-2.8,4l5.4,6.4c0,0,7.3,1.5,6.4-9.8C224.8,189.8,221.4,178.7,219.7,177.6z"
            />
            <path
              className="st11"
              d="M200.2,210c-2.9,1.1-4.2,3.4-4,4.4c0.2,1,2.4,20.6,2.4,20.6l3-0.3c0,0,1.1-7.3,1.1-10.4
				c0-1.4-0.7-8.8-0.7-8.8l17.3-3.9c5.6-0.5,6.1-4.9,6.1-4.9l-10.1-2.1C215.3,204.5,203.1,208.9,200.2,210z"
            />
            <path
              className="st11"
              d="M214,166.2c0,0-1.2,0.6-2.3,2.1c-0.7,0.9-0.1,3.3-0.1,3.3l-0.7,1.9c0,0,0.2,0.3,0.7,0.4l0.9,3
				c0,0,0,1,2.9-0.1c3.5-1.3,3.9-2.2,4.4-3.7C220.3,171.1,221.4,164.2,214,166.2z"
            />
            <path
              className="st11"
              d="M214.9,175.8l0.5,1.6c0,0,0.6,0.8,1.5,0.8c2.1,0,2.1-1.1,2.1-1.1s-0.1-2.6-0.3-4.3
				C218.5,171.1,214.9,175.8,214.9,175.8z"
            />
            <path
              className="st19"
              d="M215.2,200.4c-0.3,1.8-0.9,3.5-0.9,3.5s-6,3.3-9.9,4.6c1.2,1.9,2.2,3.8,2.8,5.8l12.1-2.8
				c1.6-0.1,2.8-0.6,3.6-1.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
				c2.9-2.3,1.9-6.2,1.8-8.6C222.4,202.7,218.3,202.3,215.2,200.4z"
            />
            <path
              className="st10"
              d="M224.7,201.5c-0.1,3.7-2.2,7-5.8,9c-3.6,2-7.7,2.5-11.6,3.7c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1l12.1-2.8
				c1.6-0.1,2.8-0.6,3.6-1.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0.1-0.1,0.3-0.2,0.4-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0
				c2.9-2.3,1.9-6.2,1.8-8.6C225.3,201.3,225,201.4,224.7,201.5z"
            />
            <path
              className="st20"
              d="M217.1,165.1c-6.2,0.1-6.2,4.1-6.2,4.1s2.5,0.8,2.5,1.6c-0.1,0.8,0.7,7.2,2.8,8.7c2.2,1.6,4.7,2.5,5.6,1.5
				c0.8-1,0.5-5.5,0-8.4C221.3,169.8,220.9,165,217.1,165.1z"
            />
            <path
              className="st4"
              d="M198.5,234c0,0-0.4,2-1.3,3.8c-0.6,1.3-4,3.5-3.7,4.1c0.3,0.6,1,1,3.2,0c1.3-0.6,3.1-2.4,4.1-2.6
				c0.9-0.2,3.2-1.4,3.5-2.6c0.1-0.7-2.4-4-2.4-4L198.5,234z"
            />
            <path
              className="st10"
              d="M196.2,214.4c0.1,0.5,0.7,5.3,1.2,10.2c0,0,0,0,0-0.1c0.5-0.8,2.7-5,3.2-6.8c1-3.5,0.5-5.4,2.3-7.4
				c2-2.3,10.3-3.7,12.6-6.4c-2.3,0.8-14.3,5.8-15.3,6.2C197.2,211.1,196,213.4,196.2,214.4z"
            />
            <path
              className="st11"
              d="M201.6,204.4c-1.6,0.2-3.9,0.6-4.3,1.6c-0.4,1-6.3,23.9-6.3,23.9l3.1,0.1c0,0,5.3-11.9,5.9-13.1
				c0.5-1.2,1.2-7.1,1.2-7.1l14.1-5.3l-1.1-3.2C214.2,201.3,203.6,204.1,201.6,204.4z"
            />
            <path
              className="st19"
              d="M204.5,203.7c0.5,1.6,0.6,3.3,0.5,4.8l10.4-4.1l-1.1-3.2C214.2,201.3,208.7,202.7,204.5,203.7z"
            />
            <path
              className="st9"
              d="M192.8,229.3c-0.8,0-1.5-0.2-2-0.4c-0.4,0.6-1.4,1.6-2.5,2.6c-1.1,0.9-5.1,1.6-5.1,2.2
				c0,0.6,0.5,1.3,2.9,1.3c1.4,0,3.9-0.9,4.8-0.7c0.9,0.2,3.5,0,4.2-1c0.4-0.5-0.3-3.4-0.5-4.3C194.1,229.2,193.5,229.3,192.8,229.3
				z"
            />
            <path
              className="st10"
              d="M189.1,233.9c-1.9,0.5-3.8,0.4-5.7,0.3c0.3,0.4,1,0.7,2.7,0.7c1.4,0,3.9-0.9,4.8-0.7c0.9,0.2,3.5,0,4.2-1
				c0.4-0.5-0.2-3.3-0.5-4.3C193.7,231.5,191.7,233.3,189.1,233.9z"
            />
            <path
              className="st10"
              d="M222,182.3c0.6,5.4,1.1,10.9,0.8,16.3c-0.1,1.2-0.2,2.4-0.5,3.5c0.3-0.1,0.7-0.1,1-0.2
				c0.8-0.1,1.6-0.4,2.3-0.7c0,0,0-0.1,0-0.1C225.1,193.9,223.5,186.8,222,182.3z"
            />
            <path
              className="st10"
              d="M201.6,204.4c-0.2,0-0.5,0.1-0.7,0.1c0.1,0,0.1,0,0.2,0c4.7,0.4,14.4,0.5,17.4-2.4l-4.3-0.7
				C214.2,201.3,203.6,204.1,201.6,204.4z"
            />
            <path
              className="st11"
              d="M215,182.4c-1.7,2.3,1.1,15-0.2,15.7c-1.3,0.8-11,3.8-11,3.8l-1.4,2.2c0,0,15.5-0.8,16.8-2.7
				c1.3-1.9,0.1-17.3,0.1-17.3C219.1,179.7,215.8,181.3,215,182.4z"
            />
          </g>
        </g>
        <g>
          <path
            className="st13"
            d="M239.9,226.6l-5.7,6.3c5.1,2,9,0.9,9.6-1.1c1.2-4.4-2.1-16.5-2.1-16.5L239.9,226.6z"
          />
          <path
            className="st10"
            d="M241.7,225.6c0-1.5-0.7-2.7-1.3-4l-0.5,5.1l-5.7,6.3c3.4,1.3,6.3,1.3,8,0.5c-0.3-0.4-0.5-0.8-0.6-1.3
			C241.1,229.8,241.8,227.9,241.7,225.6z"
          />
          <g>
            <path
              className="st21"
              d="M232.4,209.4l1.5,5.9c0,1.6,5.2-0.4,5.3-2l-0.9-4.6L232.4,209.4z"
            />
            <path
              className="st21"
              d="M231.4,208.5c-0.2,2.5,1.5,3.7,3.9,3.9c0,0,4.5-1.1,4.6-3.5l0.8-4.5l-8.9-2.1L231.4,208.5z"
            />
            <path
              className="st22"
              d="M234.1,199.3c-2,0.7-5.2,2.5-2.4,4.8c0,0,1.2,0.4,1.1,2.3l3.2,5.3c1,0.9,3-0.4,3-0.4s3.1-3.1,2-8.8
				C240.3,198.9,235.5,198.8,234.1,199.3z"
            />
            <path
              className="st21"
              d="M232.5,206.4l-0.1,2.1c0.8,0.5,1.6-0.7,1.6-1.8C233.8,205.2,232.5,206.4,232.5,206.4z"
            />
          </g>
          <path
            className="st21"
            d="M197.7,251.2l-3.4-0.3c0,0-0.2,1.8-1.4,3.3c-1.1,1.4-5.6,4.1-5.6,4.1c-0.6,0.4-1,1.2-0.7,1.9
			c0.2,0.7,0.9,1.3,2.8,1.2c3.8-0.3,6.5-2.9,7.9-3.2c2.4-0.4,1.8-2.4,1.4-3.7C198.3,253.1,197.7,251.2,197.7,251.2z"
          />
          <path
            className="st19"
            d="M198.5,253.6c-3.2-2.5-5.7,0.3-5.7,0.3l-5.4,4.3c-0.6,0.4-1,1.2-0.7,1.9c0.2,0.7,0.9,1.3,2.8,1.2
			c3.8-0.3,6.5-2.9,7.9-3.2c2.4-0.4,1.8-2.4,1.4-3.7C198.6,254.2,198.5,253.9,198.5,253.6z"
          />
          <path
            className="st23"
            d="M197.5,254.6c-0.6-0.2-2.4,0.3-3.3,0.7c-1.4,0.6-2.8,1.4-4.2,1.9c-0.5,0.2-1,0.3-1.5,0.5
			c-0.1,0-1.5,0.6-1.9,2c0,0.1,0,0.2,0.1,0.4c0.2,0.7,0.9,1.3,2.8,1.2c3.8-0.3,6.5-2.9,7.9-3.2c1.7-0.3,1.9-1.4,1.7-2.4
			C198.7,254.9,197.9,254.7,197.5,254.6z"
          />
          <path
            className="st21"
            d="M179.6,251.2c-1.2,0.2-5.8-0.7-7.8,0c-0.6,0.2-0.7,1.4,0,2.5c0.3,0.5,0.8,1.5,2.9,2c1.3,0.3,2.3-0.6,4.3,0
			c2.1,0.6,3.3,0.6,4.4-1.4c1-1.9,4.7-4.1,4.7-4.1l-3.9-1.1C184,249.2,182.2,250.7,179.6,251.2z"
          />
          <path
            className="st19"
            d="M181,250.7c-0.2,0.1-1.8,0.2-2,0.2c-1.2,0.3-5.1-0.4-7.2,0.3c-0.6,0.2-0.7,1.4,0,2.5c0.3,0.5,0.8,1.5,2.9,2
			c1.3,0.3,2.3-0.6,4.3,0c2.1,0.6,3.5,0.6,4.8-2C183.7,253.7,184.7,250.8,181,250.7z"
          />
          <path
            className="st23"
            d="M180.3,252.6c-1.2-0.6-2.8-0.5-4.1-0.8c-1.6-0.3-3-0.6-4.7-0.4c-0.3,0.5-0.3,1.4,0.3,2.3
			c0.3,0.5,0.8,1.5,2.9,2c1.3,0.3,2.3-0.6,4.3,0c1.4,0.4,2.4,0.5,3.3,0C181.9,254.4,181.4,253.3,180.3,252.6z"
          />
          <path
            className="st9"
            d="M212.5,235.9h-14.9l-15.1,14.3c0,0,1.5,0.2,1.8,0.5c0.7,0.7,0.5,1.9,0.5,1.9l15.6-10c0,0,15.5,1.6,18.8,1.6
			c5,0,7.2-3,7.2-3l0.9-11.4l-10.7-2.7L212.5,235.9z"
          />
          <path
            className="st8"
            d="M225.9,230.6c0.4,0.9,0.8,1.9,0.9,2.9l0.3-3.5l-1.9-0.5C225.5,229.8,225.7,230.2,225.9,230.6z"
          />
          <path
            className="st10"
            d="M219.1,242.1c-6,1-12.2,0-18.2,0.4c-0.1,0-0.1,0-0.2,0c0,0.1,0,0.1,0,0.2c2.2,0.2,15.4,1.5,18.5,1.5
			c5,0,7.2-3,7.2-3l0.3-4.2c-0.2,0.6-0.6,1.2-1,1.8C224,240.9,221.5,241.7,219.1,242.1z"
          />
          <path
            className="st10"
            d="M212.5,235.9h-11.2c0.3,1.7-0.3,3.6-2.2,4.8c-4.7,3-9.3,6.1-13.5,9.8c-0.3,0.3-0.7,0.5-1.1,0.7
			c0.3,0.7,0.2,1.4,0.2,1.4s15.7-10,15.8-9.9c1.3-0.8,2.9-2.9,4.2-3.6c1.1-0.8,11.5-1.2,11.4-1.8c-0.1-1.6-0.7-4.9-1.4-6.6
			L212.5,235.9z"
          />
          <path
            className="st9"
            d="M202.2,229.2c-2.1,0.3-3.8,1.7-4.3,3.7l-3.8,19c0,0,1.7-1.7,4,0.3l5.4-15.2l15.8,0.9l-2.8-10.8L202.2,229.2z"
          />
          <path
            className="st10"
            d="M202,239.7c-0.5,1.2-4.9,11.2-5.4,11.7c0.4,0.1,0.9,0.4,1.4,0.9l5.4-15.2c-0.1,0-0.3,0.1-0.3,0.1
			C202.8,237.4,202.2,239.1,202,239.7z"
          />
          <path
            className="st13"
            d="M239.2,213.3c-3.8-1.2-12.6-3.7-12.2-0.5c1,8.9-7.4,13.1-7.4,13.1s6.3,4.6,6.7,15.3c0,0,14.5-9.6,15.7-19.3
			c0.3-2.2,0.3-3.9,0.2-5.1C242,215.2,240.8,213.8,239.2,213.3z"
          />
          <path
            className="st10"
            d="M227.3,238.1c-0.4,0.2-0.8,0.3-1.3,0.4c0.1,0.9,0.2,1.8,0.2,2.7c0,0,8.1-5.3,12.7-12
			c-0.3,0.3-0.5,0.6-0.8,0.9C235.1,233,231.1,236.3,227.3,238.1z"
          />
          <path
            className="st21"
            d="M207.6,226.6c0,0-2.3,2.3-1,2.1c0.6-0.1,4.5-0.7,4.5-0.7l-0.2-3.4L207.6,226.6z"
          />
          <path
            className="st13"
            d="M230.9,212.2c0,0-3.5-2.1-5.1,0.8c-1.6,2.8-4.2,9.7-4.2,9.7l-10.7,1.9c0,0-1.1,1.1,0,3.5l11.3,0.7
			c0,0,3,0.7,4.4-1.8C229.5,221.6,235,213.4,230.9,212.2z"
          />
          <path
            className="st10"
            d="M216.5,227.7c-0.3,0-0.6-0.1-0.8-0.1c-1.6,0.1-3.2,0.2-4.8,0.3c0,0.1,0.1,0.1,0.1,0.2l11.3,0.7
			c0,0,3,0.7,4.4-1.8c2.1-3.8,5.4-9.1,5.7-12.2c-0.9,3-2.4,5.9-4.4,8.3C224.8,226.7,221.3,228.2,216.5,227.7z"
          />
        </g>
      </g>
      <g id="Animacja_3" ref={tabletRef}>
        <g>
          <g className="st1" ref={onlyTabletRef}>
            <g>
              <path
                className="st19"
                d="M73.2,189.2c-0.6,0.4-1.2,1.3-1.2,2l0.1,18c0,0.7,0.5,1,1.2,0.7c0.6-0.4,1.2-1.3,1.2-2l-0.1-18
					C74.4,189.1,73.8,188.8,73.2,189.2z"
              />
            </g>
            <g>
              <polygon
                className="st24"
                points="78.6,150.9 78.8,236.8 172,183 171.7,97.1 				"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="78.6,150.9 78.8,162.4 172,108.6 171.7,97.1 				"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M178.8,90.7L71.5,152.7c-1.9,1.1-3.5,3.8-3.5,6.1l0.2,82.4c0,2.2,1.6,3.1,3.5,2L179,181.2
					c1.9-1.1,3.5-3.8,3.5-6.1l-0.2-82.4C182.3,90.5,180.7,89.6,178.8,90.7z M73.2,184.2c0.6-0.4,1.2-0.1,1.2,0.7
					c0,0.7-0.5,1.6-1.2,2c-0.6,0.4-1.2,0.1-1.2-0.7C72,185.4,72.5,184.5,73.2,184.2z M73.3,209.8c-0.6,0.4-1.2,0.1-1.2-0.7l-0.1-18
					c0-0.7,0.5-1.6,1.2-2c0.6-0.4,1.2-0.1,1.2,0.7l0.1,18C74.4,208.6,73.9,209.5,73.3,209.8z M78.8,236.8l-0.2-86l93.2-53.8l0.2,86
					L78.8,236.8z"
              />
            </g>
            <g>
              <path
                className="st19"
                d="M73.2,184.2c-0.6,0.4-1.2,1.3-1.2,2c0,0.7,0.5,1,1.2,0.7c0.6-0.4,1.2-1.3,1.2-2
					C74.3,184.1,73.8,183.8,73.2,184.2z"
              />
            </g>
            <path
              className="st8"
              d="M174.2,88.1L66.9,150c-1.9,1.1-3.5,3.8-3.5,6.1l0.2,82.4c0,1.1,0.4,1.9,1,2.3l4.6,2.7c-0.6-0.4-1-1.1-1-2.3
				L68,158.7c0-2.2,1.6-4.9,3.5-6.1l107.2-61.9c1-0.6,1.9-0.6,2.5-0.2l-4.6-2.7C176.1,87.5,175.2,87.5,174.2,88.1z"
            />
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M162.3,172.6l-5.4,3.1c-0.6,0.3-1.1,1.2-1,1.8l0,6.2c0,0.7,0.5,0.9,1.1,0.6l5.4-3.1c0.6-0.3,1.1-1.2,1-1.8
					l0-6.2C163.4,172.5,162.9,172.2,162.3,172.6z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M150.8,179.4l-5.4,3.1c-0.6,0.3-1.1,1.2-1,1.8l0,6.2c0,0.7,0.5,0.9,1.1,0.6l5.4-3.1c0.6-0.3,1.1-1.2,1-1.8
					l0-6.2C151.9,179.3,151.4,179.1,150.8,179.4z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M139.3,186.2l-5.4,3.1c-0.6,0.3-1.1,1.2-1,1.8l0,6.2c0,0.7,0.5,0.9,1.1,0.6l5.4-3.1c0.6-0.3,1.1-1.2,1-1.8
					l0-6.2C140.3,186.1,139.9,185.9,139.3,186.2z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                className="st8"
                d="M127.7,193l-5.4,3.1c-0.6,0.3-1.1,1.2-1,1.8l0,6.2c0,0.7,0.5,0.9,1.1,0.6l5.4-3.1c0.6-0.3,1.1-1.2,1-1.8
					l0-6.2C128.7,192.9,128.3,192.6,127.7,193z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M116.1,199.8l-5.4,3.1c-0.6,0.3-1.1,1.2-1,1.8l0,6.2c0,0.7,0.5,0.9,1.1,0.6l5.4-3.1c0.6-0.3,1.1-1.2,1-1.8
					l0-6.2C117.2,199.7,116.7,199.4,116.1,199.8z"
              />
            </g>
          </g>
          <g>
            <polygon
              className="st8"
              points="82.3,166.8 82.3,205.7 115.8,186.6 115.8,147.7 			"
            />
          </g>
          <g>
            <polygon
              className="st19"
              points="117.7,146.5 117.7,164.6 133.5,155.5 133.4,137.4 			"
            />
          </g>
          <g>
            <polygon
              className="st19"
              points="135.4,136.3 135.4,154.4 151.2,145.3 151.1,127.2 			"
            />
          </g>
          <g>
            <polygon
              className="st19"
              points="153.1,126.2 153.1,144.3 168.9,135.2 168.8,117 			"
            />
          </g>
          <g>
            <polygon
              className="st8"
              points="117.7,167.2 117.7,185.3 133.5,176.2 133.4,158.1 			"
            />
          </g>
          <g>
            <polygon
              className="st8"
              points="135.4,157.1 135.4,175.2 151.2,166.1 151.1,147.9 			"
            />
          </g>
          <g>
            <polygon
              className="st8"
              points="153.1,146.9 153.1,165 168.9,155.9 168.8,137.8 			"
            />
          </g>
        </g>
        <polygon
          className="st10"
          points="83.5,236.6 112.3,220.1 112.3,199.2 83.5,215.8 	"
        />
        <g>
          <g>
            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="63.6641"
              y1="230.6919"
              x2="181.0655"
              y2="230.6919"
            >
              <stop offset="0" className="st56" />
              <stop offset="1" className="st57" />
            </linearGradient>
          </g>
        </g>
        <g>
          <g>
            <polygon
              className="st4"
              points="91.6,218 91.7,240.9 120.3,224.3 120.2,201.5 			"
            />
            <g>
              <path
                className="st13"
                d="M91.6,218l0.1,22.8l28.7-16.5l-0.1-22.8L91.6,218z M93.3,218.7l3-1.7l0,1.9l-3,1.7L93.3,218.7z
					 M93.4,238.2l0-1.9l3-1.7l0,1.9L93.4,238.2z M97.7,216.1l3-1.7l0,1.9l-3,1.7L97.7,216.1z M97.8,235.7l0-1.9l3-1.7l0,1.9
					L97.8,235.7z M102.2,213.5l3-1.7l0,1.9l-3,1.7L102.2,213.5z M102.2,233.1l0-1.9l3-1.7l0,1.9L102.2,233.1z M106.6,211l3-1.7
					l0,1.9l-3,1.7L106.6,211z M106.7,230.5l0-1.9l3-1.7l0,1.9L106.7,230.5z M111.1,208.4l3-1.7l0,1.9l-3,1.7L111.1,208.4z
					 M111.1,228l0-1.9l3-1.7l0,1.9L111.1,228z M115.5,205.8l3-1.7l0,1.9l-3,1.7L115.5,205.8z M115.6,225.4l0-1.9l3-1.7l0,1.9
					L115.6,225.4z"
              />
            </g>
          </g>
        </g>
        <polygon
          className="st26"
          points="166.7,97.9 100.7,135.8 100.7,187.6 174.9,145.2 174.4,100.3 	"
        />
        <g>
          <g>
            <path
              className="st4"
              d="M46.6,162.2c-0.6,0-1,0.4-1,1v0c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C47.6,162.7,47.2,162.2,46.6,162.2z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M46.6,158.7c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C47.6,159.2,47.2,158.7,46.6,158.7z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M46.1,155.4c-0.3,0.2-0.5,0.5-0.5,0.9v0c0,0.6,0.4,1,1,1c0.3,0,0.5-0.1,0.7-0.3c0.4-0.3,0.4-0.8,0.2-1.3
				C47.2,155.2,46.6,155.1,46.1,155.4z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M67.7,142.9L67.7,142.9c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C68.8,142.8,68.1,142.7,67.7,142.9z"
            />
            <path
              className="st4"
              d="M64.1,145L64.1,145c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C65.2,144.9,64.6,144.7,64.1,145z"
            />
            <path
              className="st4"
              d="M74.8,138.8L74.8,138.8c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4S75.3,138.5,74.8,138.8z"
            />
            <path
              className="st4"
              d="M71.2,140.9L71.2,140.9c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C72.3,140.8,71.7,140.6,71.2,140.9z"
            />
            <path
              className="st4"
              d="M53.3,151.2L53.3,151.2c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C54.4,151.1,53.8,150.9,53.3,151.2z"
            />
            <path
              className="st4"
              d="M49.8,153.3L49.8,153.3c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C50.8,153.2,50.2,153,49.8,153.3z"
            />
            <path
              className="st4"
              d="M60.5,147.1L60.5,147.1c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C61.6,147,61,146.8,60.5,147.1z"
            />
            <path
              className="st4"
              d="M56.9,149.1L56.9,149.1c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C58,149,57.4,148.9,56.9,149.1z"
            />
            <path
              className="st4"
              d="M82,134.7L82,134.7c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C83.1,134.6,82.5,134.4,82,134.7z"
            />
            <path
              className="st4"
              d="M99.9,124.3L99.9,124.3c-0.6,0.3-0.7,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C101,124.2,100.4,124.1,99.9,124.3z"
            />
            <path
              className="st4"
              d="M103.5,122.3L103.5,122.3c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C104.6,122.2,104,122,103.5,122.3z"
            />
            <path
              className="st4"
              d="M96.3,126.4L96.3,126.4c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C97.4,126.3,96.8,126.1,96.3,126.4z"
            />
            <path
              className="st4"
              d="M85.6,132.6L85.6,132.6c-0.6,0.3-0.7,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C86.7,132.5,86.1,132.3,85.6,132.6z"
            />
            <path
              className="st4"
              d="M78.4,136.7L78.4,136.7c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C79.5,136.6,78.9,136.5,78.4,136.7z"
            />
            <path
              className="st4"
              d="M92.7,128.5L92.7,128.5c-0.6,0.3-0.7,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C93.8,128.4,93.2,128.2,92.7,128.5z"
            />
            <path
              className="st4"
              d="M89.2,130.5L89.2,130.5c-0.6,0.3-0.7,0.9-0.5,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.3,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C90.3,130.4,89.6,130.3,89.2,130.5z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M107,120.2L107,120.2c-0.5,0.3-0.7,0.9-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1
				c0.5-0.3,0.6-0.9,0.4-1.4C108.1,120.1,107.5,120,107,120.2z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              className="st4"
              d="M58.1,199.6c-0.5,0.3-0.6,0.9-0.3,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1l0,0
				c0.5-0.3,0.6-0.9,0.3-1.4C59.2,199.5,58.6,199.3,58.1,199.6z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M50.2,204.4L50.2,204.4c-0.6,0.3-0.7,1-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.3-1.4C51.3,204.3,50.7,204.1,50.2,204.4z"
            />
            <path
              className="st4"
              d="M54.2,202L54.2,202c-0.6,0.3-0.7,1-0.4,1.4c0.2,0.3,0.5,0.5,0.9,0.5c0.2,0,0.4,0,0.5-0.1l0.1-0.1
				c0.5-0.3,0.6-0.9,0.3-1.4C55.3,201.9,54.7,201.7,54.2,202z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M47.5,207.1c-0.2-0.3-0.5-0.4-0.8-0.4c-0.6,0-1,0.5-1,1c0,0.4,0.2,0.7,0.5,0.9c0.2,0.1,0.3,0.1,0.5,0.1
				c0.2,0,0.4,0,0.5-0.1l0,0C47.7,208.3,47.8,207.7,47.5,207.1C47.5,207.2,47.5,207.1,47.5,207.1z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M46.6,203c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C47.6,203.4,47.2,203,46.6,203z"
            />
            <path
              className="st4"
              d="M46.6,199.3c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C47.6,199.7,47.2,199.3,46.6,199.3z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M46.6,195.6c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C47.6,196,47.2,195.6,46.6,195.6z"
            />
          </g>
        </g>
        <g>
          <path
            className="st4"
            d="M190,93.8L190,93.8c-0.5,0.5-0.4,1.1,0,1.5c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.8-0.3
			c0.4-0.4,0.4-1,0-1.4C191,93.4,190.4,93.4,190,93.8z"
          />
          <path
            className="st4"
            d="M181.9,131.4c-0.4,0.4-0.5,1-0.1,1.4l0.1,0.1c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3
			c0.4-0.4,0.4-1,0-1.4C183,131,182.3,131,181.9,131.4z"
          />
          <path
            className="st4"
            d="M185.5,102.1c0.4,0,0.7-0.2,0.9-0.5c0.3-0.5,0.2-1.1-0.3-1.4c-0.5-0.3-1.1-0.2-1.4,0.3l-0.1,0.1
			c-0.3,0.5-0.1,1,0.3,1.3C185.2,102,185.4,102.1,185.5,102.1z"
          />
          <path
            className="st4"
            d="M194.4,92.5c0.4-0.3,0.5-1,0.2-1.4c-0.3-0.4-1-0.5-1.4-0.2l-0.1,0.1c-0.4,0.4-0.5,1-0.1,1.4
			c0.2,0.2,0.5,0.3,0.8,0.3C194,92.8,194.2,92.7,194.4,92.5z"
          />
          <path
            className="st4"
            d="M190,134.5c-0.5,0-1,0.4-1,1c0,0.6,0.4,1,1,1l0.1,0c0,0,0,0,0,0c0.5,0,0.9-0.4,1-1
			C191,135,190.5,134.5,190,134.5z"
          />
          <path
            className="st4"
            d="M186.3,133.6c-0.5-0.2-1.1,0-1.3,0.5c-0.2,0.5,0,1.1,0.5,1.3l0.1,0c0.1,0.1,0.3,0.1,0.4,0.1
			c0.4,0,0.7-0.2,0.9-0.6C187.1,134.5,186.8,133.9,186.3,133.6z"
          />
          <path
            className="st4"
            d="M188.6,96.9c-0.4-0.3-1.1-0.3-1.4,0.2l0,0.1c-0.3,0.4-0.3,1.1,0.2,1.4c0.2,0.1,0.4,0.2,0.6,0.2
			c0.3,0,0.6-0.1,0.8-0.4l0.1-0.1C189.1,97.8,189,97.2,188.6,96.9z"
          />
          <path
            className="st4"
            d="M180.1,127.7c-0.5,0.2-0.8,0.8-0.6,1.3l0,0.1c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1
			c0.5-0.2,0.7-0.8,0.5-1.3C181.2,127.7,180.6,127.5,180.1,127.7z"
          />
          <path
            className="st4"
            d="M181.4,109.4c0.1,0.1,0.3,0.1,0.4,0.1c0.4,0,0.8-0.2,0.9-0.6l0-0.1c0.2-0.5-0.1-1.1-0.6-1.3
			c-0.5-0.2-1.1,0.1-1.3,0.6l0,0.1C180.6,108.6,180.9,109.2,181.4,109.4z"
          />
          <path
            className="st4"
            d="M215.5,92.4c0.2,0,0.4,0,0.5-0.1c0.5-0.3,0.6-0.9,0.3-1.4l-0.1-0.1c-0.3-0.5-0.9-0.6-1.4-0.3
			c-0.5,0.3-0.6,0.9-0.3,1.4C214.8,92.2,215.2,92.4,215.5,92.4z"
          />
          <path
            className="st4"
            d="M212.7,89.3c0.3,0,0.6-0.1,0.8-0.4c0.3-0.4,0.2-1.1-0.2-1.4l-0.1-0.1c-0.4-0.3-1-0.2-1.4,0.3
			c-0.3,0.4-0.2,1.1,0.3,1.4C212.3,89.3,212.5,89.3,212.7,89.3z"
          />
          <path
            className="st4"
            d="M216.1,95.4L216.1,95.4c0.1,0.5,0.5,0.8,1,0.8c0.1,0,0.2,0,0.3,0c0.5-0.1,0.8-0.7,0.7-1.3c0,0,0,0,0,0
			c-0.1-0.5-0.7-0.8-1.2-0.7C216.3,94.3,216,94.8,216.1,95.4z"
          />
          <path
            className="st4"
            d="M180.1,113.4c0.1,0,0.2,0,0.3,0c0.4,0,0.8-0.3,1-0.7l0-0.1c0.2-0.5-0.2-1.1-0.7-1.2c-0.5-0.1-1.1,0.2-1.2,0.7
			l0,0.1C179.3,112.7,179.6,113.2,180.1,113.4z"
          />
          <path
            className="st4"
            d="M179.1,121.6C179.1,121.6,179.1,121.6,179.1,121.6c0.6,0,1-0.4,1-1l0-0.1c0-0.6-0.4-1-1-1c-0.6,0-1,0.5-1,1
			c0,0,0,0.1,0,0.1C178.1,121.1,178.5,121.6,179.1,121.6z"
          />
          <path
            className="st4"
            d="M180.3,124.5c-0.1-0.5-0.6-0.9-1.1-0.8c-0.5,0.1-0.9,0.6-0.8,1.1l0,0.1c0.1,0.5,0.5,0.8,1,0.8
			c0.1,0,0.1,0,0.2,0C180.1,125.6,180.4,125,180.3,124.5z"
          />
          <path
            className="st4"
            d="M179.3,117.4c0.1,0,0.1,0,0.2,0c0.5,0,0.9-0.3,1-0.8l0-0.1c0.1-0.5-0.3-1.1-0.8-1.1c-0.5-0.1-1.1,0.3-1.1,0.8
			l0,0.1C178.4,116.8,178.8,117.3,179.3,117.4z"
          />
          <path
            className="st4"
            d="M184,103.7c-0.5-0.2-1.1,0-1.3,0.4l0,0.1c-0.3,0.5-0.1,1.1,0.4,1.4c0.1,0.1,0.3,0.1,0.5,0.1
			c0.4,0,0.7-0.2,0.9-0.5l0.1-0.1C184.7,104.6,184.5,104,184,103.7z"
          />
          <path
            className="st4"
            d="M214.8,114.4c-0.5-0.2-1.1,0-1.3,0.5l0,0.1c-0.2,0.5,0,1.1,0.5,1.3c0.1,0.1,0.3,0.1,0.4,0.1
			c0.4,0,0.7-0.2,0.9-0.6l0-0.1C215.5,115.2,215.3,114.6,214.8,114.4z"
          />
          <path
            className="st4"
            d="M212.9,118.1c-0.5-0.3-1.1-0.1-1.4,0.4l-0.1,0.1c-0.3,0.5-0.1,1,0.4,1.3c0.1,0.1,0.3,0.1,0.5,0.1
			c0.4,0,0.7-0.2,0.9-0.5l-0.8-0.5l0.9,0.5C213.6,119,213.4,118.4,212.9,118.1z"
          />
          <path
            className="st4"
            d="M193.8,134c-0.5,0.1-0.8,0.7-0.7,1.2c0.1,0.5,0.5,0.7,1,0.7c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
			c0.5-0.1,0.8-0.7,0.7-1.2C194.9,134.2,194.3,133.9,193.8,134z"
          />
          <path
            className="st4"
            d="M216.2,110.5c-0.5-0.2-1.1,0.1-1.3,0.7c-0.2,0.5,0.1,1.1,0.6,1.3c0.1,0,0.2,0.1,0.3,0.1
			c0.4,0,0.8-0.3,0.9-0.7l0-0.1C217,111.2,216.7,110.7,216.2,110.5z"
          />
          <path
            className="st4"
            d="M209.4,121.9L209.4,121.9c-0.4,0.5-0.3,1.2,0.2,1.5c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0,0.6-0.1,0.8-0.4l0.1-0.1
			c0.3-0.4,0.2-1.1-0.2-1.4C210.3,121.4,209.7,121.5,209.4,121.9z"
          />
          <path
            className="st4"
            d="M217.7,98.2c-0.6,0-1,0.5-0.9,1.1l0,0.1c0,0.5,0.5,0.9,1,0.9c0,0,0,0,0.1,0c0.6,0,1-0.5,0.9-1.1l0-0.1
			C218.7,98.6,218.3,98.2,217.7,98.2z"
          />
          <path
            className="st4"
            d="M217.2,106.4c-0.5-0.1-1.1,0.2-1.2,0.8l0,0.1c-0.1,0.5,0.2,1.1,0.8,1.2c0.1,0,0.1,0,0.2,0
			c0.5,0,0.9-0.3,1-0.8l0-0.1C218.1,107.1,217.8,106.6,217.2,106.4z"
          />
          <path
            className="st4"
            d="M217.7,102.4c-0.6,0-1,0.4-1.1,1c0,0.5,0.4,1,0.9,1.1c0,0,0.1,0,0.1,0c0.5,0,1-0.4,1-0.9c0,0,0-0.1,0-0.1
			C218.7,102.9,218.3,102.4,217.7,102.4z"
          />
          <path
            className="st4"
            d="M210,86.9c0.1-0.5-0.2-1.1-0.8-1.2l-0.1,0c-0.5-0.1-1,0.2-1.1,0.8c-0.1,0.5,0.3,1.1,0.8,1.2
			c0.1,0,0.1,0,0.2,0C209.5,87.6,209.9,87.3,210,86.9z"
          />
          <path
            className="st4"
            d="M197.5,132.6c-0.5,0.2-0.7,0.8-0.5,1.3c0.2,0.4,0.5,0.6,0.9,0.6c0.1,0,0.3,0,0.4-0.1l0.1,0
			c0.5-0.2,0.7-0.8,0.4-1.3C198.6,132.6,197.9,132.4,197.5,132.6z"
          />
          <path
            className="st4"
            d="M201.2,88.4c0.5-0.2,0.8-0.8,0.6-1.3c-0.2-0.5-0.8-0.8-1.3-0.6l-0.1,0c-0.5,0.2-0.7,0.8-0.5,1.3
			c0.2,0.4,0.5,0.6,1,0.6C201,88.5,201.1,88.5,201.2,88.4z"
          />
          <path
            className="st4"
            d="M206.8,125.2c-0.4,0.4-0.3,1,0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.6-0.1,0.7-0.3l0.1-0.1
			c0.4-0.4,0.3-1-0.1-1.4C207.8,124.7,207.2,124.7,206.8,125.2z"
          />
          <path
            className="st4"
            d="M197.6,90.2L197.6,90.2c0.6-0.3,0.7-1,0.4-1.4c-0.3-0.5-0.9-0.6-1.4-0.3l-0.1,0.1c-0.5,0.3-0.6,0.9-0.3,1.4
			c0.2,0.3,0.5,0.5,0.9,0.5C197.2,90.4,197.4,90.3,197.6,90.2z"
          />
          <path
            className="st4"
            d="M204.8,85.5c0,0-0.1,0-0.1,0l0.1,1l-0.1-1c-0.5,0.1-0.9,0.6-0.8,1.1c0.1,0.5,0.5,0.9,1,0.9c0,0,0.1,0,0.1,0
			c0.5-0.1,0.9-0.6,0.9-1.1C205.8,85.8,205.3,85.4,204.8,85.5z"
          />
          <path
            className="st4"
            d="M204.1,128L204.1,128c-0.5,0.5-0.5,1.1-0.1,1.5c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l0.1-0.1
			c0.4-0.4,0.4-1,0.1-1.4C205.1,127.6,204.5,127.6,204.1,128z"
          />
          <path
            className="st4"
            d="M200.9,130.6c-0.5,0.3-0.6,0.9-0.2,1.4c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l0.1-0.1
			c0.4-0.3,0.5-0.9,0.2-1.4C202,130.3,201.3,130.3,200.9,130.6z"
          />
        </g>
        <g>
          <polygon
            className="st8"
            points="109.5,155.6 109.6,173.7 181.7,132.1 181.6,116.4 		"
          />
          <g className="st1">
            <g>
              <path
                className="st27"
                d="M179.2,128.2l-5.5,3.2c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l5.5-3.2c0.2-0.1,0.4-0.4,0.4-0.7
					C179.6,128.2,179.4,128.1,179.2,128.2z"
              />
            </g>
            <g>
              <path
                className="st27"
                d="M171,127.6l-8.8,5.1c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l8.8-5.1c0.2-0.1,0.4-0.4,0.4-0.7
					C171.4,127.6,171.3,127.5,171,127.6z"
              />
            </g>
            <g>
              <path
                className="st27"
                d="M159.8,131.6c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l21.9-12.6l0-1L159.8,131.6z"
              />
            </g>
            <g>
              <path
                className="st27"
                d="M176.8,126.7c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l4.8-2.8l0-0.9L176.8,126.7z"
              />
            </g>
            <g>
              <path
                className="st19"
                d="M109.5,122l0.1,38.4l8.2-17.7l7.5,6.2l17.9-10.3l8.6-17.9l7.2,5l11.4-23.7l11.2,10.6l-0.1-32.2L109.5,122z
					 M117.6,135c-2.3,1.3-4.1,0.3-4.1-2.3c0-2.6,1.8-5.7,4.1-7c2.3-1.3,4.1-0.3,4.1,2.3C121.7,130.6,119.8,133.7,117.6,135z
					 M154.2,113.8l-10.4,6c-1.5,0.8-2.6,0.2-2.6-1.5c0-0.5,0.1-1.1,0.3-1.6l-5,2.9c-1.3,0.8-2.4,0.1-2.4-1.4c0-1.5,1-3.3,2.3-4
					c0.1-2,1.5-4.4,3.2-5.3c1.1-0.6,2-0.6,2.6,0c0.4-0.5,0.8-0.9,1.2-1.2c1.2-0.7,2.2-0.2,2.3,1.1l0.1-0.1c0.2-0.1,0.4-0.2,0.5-0.2
					c0.3-0.3,0.6-0.5,1-0.7c1.2-0.7,2.2-0.6,2.9,0c0.4-0.5,0.9-1,1.4-1.3c1.3-0.8,2.4-0.2,2.5,1.3l0.1-0.1c1.5-0.8,2.6-0.2,2.6,1.5
					C156.9,110.9,155.7,112.9,154.2,113.8z"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="170.4,102.1 159,125.7 151.7,120.7 143.1,138.7 181.6,116.4 181.6,112.6 				"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M146.3,108.5c-1.4,1.3-2.5,3.4-2.6,5.3c-1,0.6-1.8,1.7-2.3,2.9l4.3-2.5c1.3-0.8,2.4-2.6,2.4-4.1
					C148.2,108.8,147.4,108.2,146.3,108.5z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M105.4,119.9l0.2,60.5l80.1-46.2l-0.2-60.5L105.4,119.9z M109.6,173.7l-0.1-51.7l72.1-41.6l0.1,32.2v0
					l0,3.8v0l0,2.6l0,1l0,4l0,0.9l0,7.2L109.6,173.7z"
              />
            </g>
            <g>
              <path
                className="st27"
                d="M178.3,123.4l-5.5,3.2c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l5.5-3.2c0.2-0.1,0.4-0.4,0.4-0.7
					C178.7,123.4,178.6,123.3,178.3,123.4z"
              />
            </g>
            <g>
              <path
                className="st27"
                d="M174.5,128.1l-5.5,3.2c-0.2,0.1-0.4,0.4-0.4,0.7c0,0.3,0.2,0.4,0.4,0.2l5.5-3.2c0.2-0.1,0.4-0.4,0.4-0.7
					C174.9,128.1,174.7,127.9,174.5,128.1z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M109.6,173.7l16-9.3c-0.2,0.1-0.4,0.3-0.7,0.4L109.6,173.7z"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="109.6,173.7 109.6,173.7 109.6,173.7 				"
              />
            </g>
            <g>
              <polygon
                className="st9"
                points="132.6,155.1 125.3,149 117.8,142.7 109.6,160.4 109.6,173.7 125,164.8 146.8,152.2 137.2,146.7 
									"
              />
            </g>
          </g>
          <g>
            <path
              className="st13"
              d="M117.6,125.6c-2.3,1.3-4.1,4.5-4.1,7c0,2.6,1.8,3.6,4.1,2.3c2.3-1.3,4.1-4.5,4.1-7
				C121.6,125.4,119.8,124.3,117.6,125.6z"
            />
          </g>
          <g>
            <path
              className="st28"
              d="M154.2,107.7l-0.1,0.1c-0.1-1.5-1.2-2.1-2.5-1.3c-0.5,0.3-1,0.8-1.4,1.3c-0.7-0.6-1.7-0.7-2.9,0
				c-0.3,0.2-0.7,0.4-1,0.7c1.1-0.3,1.8,0.3,1.9,1.6c0,1.5-1.1,3.3-2.4,4.1l-4.3,2.5c-0.2,0.5-0.3,1.1-0.3,1.6
				c0,1.7,1.2,2.3,2.6,1.5l10.4-6c1.5-0.8,2.6-2.9,2.6-4.6C156.9,107.6,155.7,106.9,154.2,107.7z"
            />
          </g>
          <g>
            <path
              className="st4"
              d="M145.7,108.8c-0.1-1.3-1.1-1.8-2.3-1.1c-0.5,0.3-0.9,0.7-1.2,1.2c-0.6-0.6-1.5-0.6-2.6,0
				c-1.7,1-3.1,3.4-3.2,5.3c-1.3,0.8-2.3,2.6-2.3,4c0,1.5,1.1,2.1,2.4,1.4l5-2.9c0.4-1.2,1.3-2.3,2.3-2.9c0.1-1.8,1.1-4,2.6-5.3
				C146.2,108.6,146,108.6,145.7,108.8L145.7,108.8z"
            />
          </g>
        </g>
        <g>
          <path
            className="st4"
            d="M83.9,117l0-0.1l0-0.1c-0.3-1.1-1.2-1.7-2.4-1.4c-0.3,0.1-0.6,0.2-1,0.4c-1,0.6-1.9,1.6-2.5,2.8l-6.6,3.8
			c-0.6-0.4-1.6-0.4-2.5,0.2c-0.3,0.2-0.6,0.4-1,0.7c-1.2,1.1-2,2.6-2.3,4.1l0,0.1l0,0.1l-1.8,9.3c-0.4,1.5,0.1,2.8,1.2,2.8
			c0.4,0,0.8-0.1,1.2-0.3c0.9-0.5,1.7-1.5,2.2-2.7l2.1-6l8.6-4.8l2.1,3.4c0.5,0.6,1.3,0.7,2.2,0.2c0.4-0.2,0.8-0.6,1.2-1.1
			c1.1-1.3,1.6-3.1,1.1-4.2L83.9,117z M71.4,126.6c-0.2,0.6-0.6,1.2-1,1.8c-0.2,0.3-0.5,0.6-0.7,0.8c-0.3,0.2-0.5,0.4-0.8,0.6
			c-1.2,0.7-2.4,0.4-2.8-0.8c-0.2-0.5-0.1-1.1,0-1.7c0.2-1.2,1-2.6,2-3.5c0.3-0.2,0.5-0.4,0.8-0.6c0.6-0.4,1.2-0.5,1.7-0.3
			c0.5,0.1,0.9,0.5,1,1.1C71.9,124.7,71.8,125.6,71.4,126.6z M72.9,124.5l0-1.1l1.5-0.9l0,1.1L72.9,124.5z M75.1,123.2l0-1.1
			l1.5-0.9l0,1.1L75.1,123.2z M81.4,122.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.5,0.3-1.1,0.4-1.5,0.4c-0.4-0.1-0.8-0.3-1-0.7
			c-0.1-0.1-0.2-0.3-0.2-0.5c-0.3-1.2,0.1-2.8,1-4.2c0.3-0.4,0.6-0.8,0.9-1.1c0.3-0.2,0.5-0.4,0.8-0.6c1.2-0.7,2.4-0.4,2.8,0.8
			l0,0.1C83.8,118.8,82.9,121,81.4,122.4z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M51.6,171.4l-2.5,1.5l4.1-11.9c0.1-0.3,0-0.5-0.2-0.5c-0.1,0-0.1,0-0.2,0.1c-0.1,0.1-0.3,0.3-0.4,0.5
			l-4.3,12.4l-0.1,0.1l-3-6.2c-0.1-0.1-0.2-0.2-0.4-0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.2-0.3,0.6-0.2,0.7l2.9,5.9l-9.1,5.3
			c-1.4,0.8-2.6,2.8-2.6,4.5l0,10.4c0,1.6,1.2,2.3,2.6,1.5c0.4-0.2,0.7-0.1,0.7,0.4l0,0.3c0,0.5,0.3,0.7,0.7,0.4l10.6-6.1
			c0.4-0.2,0.7-0.8,0.7-1.3l0-0.3c0-0.5,0.3-1,0.7-1.3c1.4-0.8,2.6-2.8,2.6-4.5l0-10.4C54.2,171.3,53,170.6,51.6,171.4z M37.6,192.5
			l0-9.5l14.6-8.4l0,9.5L37.6,192.5z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M174.9,58.5c-3.9,2.3-7.2,7.8-7.1,12.4l0,4.8c0,0.3,0.2,0.4,0.5,0.3l0,0.5c0,1.3,1,1.9,2.1,1.2
			c0.2-0.1,0.4-0.5,0.4-0.7l0-6.7c0-0.3-0.2-0.4-0.4-0.2c-0.6,0.4-1.2,1-1.6,1.8l0-1.5c0-3.9,2.7-8.7,6.1-10.6
			c3.4-2,6.2-0.4,6.2,3.5l0,1.5c-0.4-0.3-1-0.3-1.6,0.1c-0.2,0.1-0.4,0.5-0.4,0.7l0,6.7c0,0.3,0.2,0.4,0.4,0.2
			c1.2-0.7,2.1-2.3,2.1-3.6l0-0.5c0.3-0.2,0.5-0.5,0.5-0.9l0-4.8C182.1,58,178.8,56.2,174.9,58.5z"
          />
        </g>
        <g>
          <path
            className="st4"
            d="M209.9,93.4c-0.8,0.4-1.4,1.5-1.4,2.4c0,0.4,0.1,0.6,0.3,0.8l-2.6,6.1c-0.1,0-0.2,0.1-0.3,0.1
			c-0.1,0.1-0.2,0.1-0.3,0.2L203,100c0.2-0.4,0.3-0.8,0.3-1.1c0-0.9-0.6-1.2-1.4-0.8c-0.8,0.4-1.4,1.5-1.4,2.4
			c0,0.4,0.1,0.6,0.3,0.8l-2.6,6.1c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0.4-1.4,1.5-1.4,2.4c0,0.9,0.6,1.2,1.4,0.8c0.8-0.4,1.4-1.5,1.4-2.4
			c0-0.4-0.1-0.6-0.3-0.8l2.6-6.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2l2.6,3.1c-0.2,0.4-0.3,0.8-0.3,1.1
			c0,0.9,0.6,1.2,1.4,0.8c0.8-0.4,1.4-1.5,1.4-2.4c0-0.4-0.1-0.6-0.3-0.8l2.6-6.1c0.1,0,0.2-0.1,0.3-0.1c0.8-0.4,1.4-1.5,1.4-2.4
			C211.3,93.4,210.7,93,209.9,93.4z"
          />
        </g>
        <g>
          <path
            className="st10"
            d="M88.3,176.2l-20.2,11.7v17l20-11.6l5.7,1.2l0-15.2C93.8,175.8,91.3,174.4,88.3,176.2z"
          />
        </g>
        <g className="st29">
          <path
            className="st4"
            d="M90.7,173.1l-32.3,18.6c-3,1.7-5.4,5.9-5.4,9.4l0,4.5c0,3.4,2.4,4.8,5.4,3.1l32.1-18.5l5.7,1.2l0-15.2
			C96.1,172.7,93.7,171.3,90.7,173.1z"
          />
        </g>
        <polygon
          className="st10"
          points="151.2,192.8 182.5,174.9 182.5,127.8 151.2,145.7 	"
        />
        <g>
          <g>
            <polygon
              className="st8"
              points="160.8,173.4 160.8,180.9 167.3,177.1 167.3,169.7 			"
            />
          </g>
          <g>
            <polygon
              className="st9"
              points="177.9,163.6 177.9,171 184.4,167.3 184.3,159.8 			"
            />
          </g>
          <g className="st1">
            <g>
              <polygon
                className="st13"
                points="194.9,153.7 194.9,161.2 201.4,157.4 201.4,150 				"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M199.1,146.1l-7.8,4.5c0.1,0.1,0.1,0.2,0.1,0.4c0,0.2,0,0.4-0.1,0.5l7.8-4.5c0.2-0.1,0.3-0.3,0.3-0.5l0-0.2
					C199.5,146.1,199.3,146,199.1,146.1z"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="186.4,158.7 186.4,166.1 192.9,162.4 192.9,154.9 				"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M190.7,150.6c-0.3,0.2-0.5,0.5-0.6,0.8c-0.1,0.2-0.1,0.4-0.1,0.5c0,0.2,0,0.3,0.1,0.4
					c0.1,0.2,0.4,0.2,0.6,0c0.3-0.2,0.5-0.5,0.6-0.8c0.1-0.2,0.1-0.4,0.1-0.5c0-0.2,0-0.3-0.1-0.4
					C191.2,150.5,190.9,150.4,190.7,150.6z M190.7,151.9c-0.2,0.1-0.4,0-0.4-0.2c0-0.2,0.2-0.5,0.4-0.6c0.2-0.1,0.4,0,0.4,0.2
					C191,151.5,190.9,151.8,190.7,151.9z"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M190.7,151c-0.2,0.1-0.4,0.4-0.4,0.6c0,0.2,0.2,0.3,0.4,0.2c0.2-0.1,0.4-0.4,0.4-0.7
					C191,151,190.9,150.9,190.7,151z"
              />
            </g>
            <g>
              <path
                className="st8"
                d="M160.8,166.4l0,4.7l40.5-23.4l0-4.7L160.8,166.4z M199.1,147l-7.8,4.5c-0.1,0.3-0.4,0.6-0.6,0.8
					c-0.3,0.2-0.5,0.1-0.6,0l-27,15.6c-0.2,0.1-0.3,0-0.3-0.2l0-0.2c0-0.2,0.1-0.5,0.3-0.6l27-15.6c0.1-0.3,0.4-0.6,0.6-0.8
					c0.3-0.2,0.5-0.1,0.6,0l7.8-4.5c0.2-0.1,0.3,0,0.3,0.2l0,0.2C199.5,146.7,199.3,146.9,199.1,147z"
              />
            </g>
            <g>
              <path
                className="st19"
                d="M181,134.5c-3.5,2-6.3,6.9-6.3,10.9c0,4,2.9,5.6,6.3,3.6c3.5-2,6.3-6.9,6.3-10.9
					C187.3,134.1,184.5,132.5,181,134.5z M179.3,145.9l0-6.2l4.5,0.5L179.3,145.9z"
              />
            </g>
            <g>
              <path
                className="st13"
                d="M160.7,139.8l0.1,26.6l40.5-23.4l-0.1-26.6L160.7,139.8z M181,149.7c-3.8,2.2-6.9,0.4-6.9-3.9
					c0-4.4,3.1-9.7,6.9-11.9c3.8-2.2,6.9-0.4,6.9,3.9C187.9,142.1,184.8,147.5,181,149.7z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M181,133.8c-3.8,2.2-6.9,7.5-6.9,11.9c0,4.4,3.1,6.1,6.9,3.9c3.8-2.2,6.9-7.5,6.9-11.9
					C187.9,133.4,184.8,131.6,181,133.8z M181,149c-3.5,2-6.3,0.4-6.3-3.6c0-4,2.8-8.9,6.3-10.9c3.5-2,6.3-0.4,6.4,3.6
					C187.4,142.1,184.5,147,181,149z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M157.8,138.6l0.1,46.8l46.4-26.8l-0.1-46.8L157.8,138.6z M160.7,139.8l40.5-23.4l0.1,26.6l0,4.7L160.8,171
					L160.7,139.8z M160.8,180.9l0-7.4l6.5-3.7l0,7.4L160.8,180.9z M169.4,175.9l0-7.4l6.5-3.7l0,7.4L169.4,175.9z M177.9,171l0-7.4
					l6.5-3.7l0,7.4L177.9,171z M186.4,166.1l0-7.4l6.5-3.7l0,7.4L186.4,166.1z M194.9,161.2l0-7.4l6.5-3.7l0,7.4L194.9,161.2z"
              />
            </g>
            <g>
              <polygon
                className="st19"
                points="169.3,168.5 169.4,175.9 175.8,172.2 175.8,164.8 				"
              />
            </g>
            <g>
              <polygon
                className="st4"
                points="179.3,145.9 183.8,140.2 179.3,139.7 				"
              />
            </g>
            <g>
              <path
                className="st9"
                d="M190,151.4L163,167c-0.2,0.1-0.3,0.3-0.3,0.6l0,0.2c0,0.2,0.1,0.3,0.3,0.2l27-15.6
					c-0.1-0.1-0.1-0.2-0.1-0.4C189.9,151.7,190,151.5,190,151.4z"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Animacja_4" ref={secondPeopleRef}>
        <g>
          <path
            className="st30"
            d="M208.4,162.9c-1.8,1.1-1.8,2.8,0,3.8l117,66.8c1.8,1.1,4.8,1.1,6.6,0l72.6-42.3L281,120.6L208.4,162.9z"
          />
        </g>
        <g>
          <g className="st1">
            <g>
              <path
                className="st4"
                d="M413,167.5l-117-66.8c-1.8-1.1-4.8-1.1-6.6,0l-81,48c-1.8,1.1-1.8,2.8,0,3.8l117,66.8
					c1.8,1.1,4.8,1.1,6.6,0l81-48C414.8,170.3,414.8,168.6,413,167.5z"
              />
            </g>
            <path
              className="st31"
              d="M413,171.3l-81,48c-1.8,1.1-4.8,1.1-6.6,0l-117-66.8c-0.9-0.5-1.4-1.2-1.4-1.9l0,4.8c0,0.7,0.5,1.4,1.4,1.9
				l117,66.8c1.8,1.1,4.8,1.1,6.6,0l81-48c0.9-0.5,1.4-1.2,1.4-1.9l0-4.8C414.3,170.1,413.9,170.8,413,171.3z"
            />
          </g>
        </g>
        <g>
          <path
            className="st32"
            d="M278.5,147.9c-2.5-1.2-5-2.5-7.7-3.1c0-0.2,0.1-0.5,0.1-0.7c0-3.7-6.3-6.7-14.1-6.7c-7.8,0-14.1,3-14.1,6.7
			c0,3.7,6.3,6.7,14.1,6.7c0,0,0.1,0,0.1,0c2.6,0,5.2,0.4,7.6,1.5c7.1,3.1,19.4,8.4,20.6,6.9c1.6-2,1-5-0.5-7
			C283,150.2,280.7,149,278.5,147.9z"
          />
          <g className="st33">
            <g>
              <polygon
                className="st34"
                points="258.8,61 317.5,95.6 317.6,46.9 258.9,12.3 				"
              />
            </g>
          </g>
          <g>
            <g className="st1">
              <g>
                <polygon
                  className="st35"
                  points="343,138.6 343.1,143.4 347.2,141 347.2,136.3 					"
                />
              </g>
              <g>
                <polygon
                  className="st36"
                  points="288.2,106.8 288.2,111.5 343.1,143.4 343,138.6 					"
                />
              </g>
              <g>
                <polygon
                  className="st37"
                  points="288.2,106.8 343,138.6 347.2,136.3 292.3,104.4 					"
                />
              </g>
            </g>
          </g>
          <polygon
            className="st38"
            points="291.2,105.8 345.5,137.2 345.6,124.7 291.3,93.4 		"
          />
          <g>
            <g className="st1">
              <g>
                <polygon
                  className="st34"
                  points="291.2,93.3 345.5,124.6 345.6,68.3 291.3,36.9 					"
                />
              </g>
            </g>
            <g>
              <polygon
                className="st8"
                points="291.3,42.9 345.6,74.2 345.6,67.9 291.3,36.5 				"
              />
            </g>
          </g>
          <g>
            <path
              className="st39"
              d="M283.7,150.8c-1.1-1.1-2.1-2.4-2.1-2.4l-3.2-0.5c0,0,0.1,2.4,0,3.3c-0.1,0.9,0.4,2,0.7,2.3
				c0.3,0.2,2.8,1.1,5.7,2.2c2.9,1.1,3.7-0.2,3.9-0.5C288.9,154.8,284.8,151.9,283.7,150.8z"
            />
            <path
              className="st32"
              d="M281.7,148.5C281.7,148.4,281.6,148.4,281.7,148.5l-3.3-0.6c0,0,0.1,2.4,0,3.3c-0.1,0.9,0.4,2,0.7,2.3
				c0.3,0.2,2.8,1.1,5.7,2.2c1,0.4,1.7,0.5,2.3,0.4C284.1,154.3,281.8,151.9,281.7,148.5z"
            />
            <g>
              <path
                className="st13"
                d="M279,116.1c-6-3.1-17-11.4-25.1-32c-2.3-1.2-5.1-1.2-7.3,0.2l-11.3,7c-1.2,1.4-1.8,3.2-1.7,5.1
					c0.6,7.5,2.3,27.6,5.1,32.8c3.5,6.5,8.2,11.3,16.8,12.7c4.8,0.8,21.3-2.6,26.9-17.5l0.2-1.2C283,120.3,281.6,117.4,279,116.1z"
              />
              <path
                className="st6"
                d="M277.7,116.9c-13.2-7.5-20.9-22.7-23.8-29.1c-0.7-1.6-2.6-2.2-4.1-1.3l-10.6,6.1c-1.7,1-2.6,2.8-2.4,4.8
					c2.2,18.5,10.3,31,14.8,36.7c1.8,2.2,4.7,3.2,7.5,2.6c12.6-3,18.1-8,20.5-11.9C281.4,122,280.4,118.5,277.7,116.9z"
              />
            </g>
            <path
              className="st39"
              d="M282.4,153.9c-2.2-2.1-3.9-3.6-3.9-3.6l-1.7,2.8l0.1,1.3c0,0-0.1,1.3,0.9,2.1c0.7,0.6,2.9,1.6,4.5,2.6
				c3,1.9,6.2,0.4,5.8-0.9C287.8,157,284.6,156.1,282.4,153.9z"
            />
            <path
              className="st8"
              d="M275.6,124.1L259.5,114l-4.4,8.4l17.7,9.4l4.8,17c3,2.2,4.5-1,4.5-1l-2.6-18.9
				C279.2,126.8,277.3,125,275.6,124.1z"
            />
            <path
              className="st40"
              d="M276.3,135c-0.7-2.4-0.7-5.2-2.5-7c-1.5-1.5-3.7-2.2-5.6-2.9c-4-1.3-8.2-2-12.3-3.3
				c-0.2-0.1-0.3-0.1-0.4-0.2l-0.4,0.7l17.7,9.4l4.8,17c3,2.2,4.5-1,4.5-1l-0.4-2.7C279.3,142,277.4,138.7,276.3,135z"
            />
            <path
              className="st8"
              d="M268.3,128.8c-5.8-4.4-7.8-7.1-7.8-7.1v-8.3l-13.1,7.1l1.2,4c2.2,3.3,3.8,4.3,7.4,6.1l10.2,6.1l10.5,17.1
				c0,0,3.6,1.8,4.4-2.1l-7.2-17C273.7,134.7,271.5,131.3,268.3,128.8z"
            />
            <path
              className="st32"
              d="M254.8,125.1c-2.9-1.9-3.8-4.3-3.5-6.7l-4.1,2.2l1.2,4c2.2,3.3,3.8,4.3,7.4,6.1l10.2,6.1l10.5,17.1
				c0,0,3,1.5,4.2-1.3c-4.8-4.8-7.2-12.2-11.1-17.8C265.9,129.6,259.8,128.3,254.8,125.1z"
            />
            <path
              className="st9"
              d="M255.7,95.7c-1.1-2.3-3.2-3.1-5.5-2.7c-2.3,0.5-11.1,3.8-11.1,9c0,6.9,6,17,9.8,23.2c0,0,3.1,1.2,8.9-3
				c2.5-1.8,5.9-5.5,5.9-5.5s-3.1-4-4.1-8.1C257.9,102.5,256.1,96.4,255.7,95.7z"
            />
            <path
              className="st32"
              d="M251.6,111.5c-4.5-1.5-7-5.8-10.7-8.3c-0.6,0.3-1.2,0.6-1.7,1c1,6.7,6.2,15.4,9.6,21c0,0,3.1,1.2,8.9-3
				c2.5-1.8,5.9-5.5,5.9-5.5s-2.2-2.8-3.5-6.2C257.5,112,254.3,112.4,251.6,111.5z"
            />
            <g>
              <path
                className="st7"
                d="M244.5,96.3c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9v-4.7h-5.4L244.5,96.3z"
              />
              <path
                className="st7"
                d="M243.8,92.3c0,2.4,4.3,3.8,4.3,3.8c2.3,0,3.6-1.1,3.6-3.5v-6.2l-8.4,1.5L243.8,92.3z"
              />
              <path
                className="st41"
                d="M250.7,83.9c-3.3-2.1-6.2-0.9-8.2,1.6c-1,1.2-0.3,3.7,0,4.8c0.6,2.6,2.2,4,2.2,4l0.3-4.1l0.5,0.8l0.7-0.5
					c0,0-0.3-1.8-0.3-2c0-1,5.9-1.1,5.9-1.1S254.3,86.1,250.7,83.9z"
              />
              <path
                className="st7"
                d="M243.5,90.4c-0.1,1.1,0.6,2.2,1.4,1.8v-2.1C244.9,90.2,243.6,89,243.5,90.4z"
              />
            </g>
            <path
              className="st9"
              d="M258.1,112.4l-10.9,1.6c0,0-1.8-7-2.3-9.5c-0.3-1.5-1.3-7.2-5.9-3.2c0,0,1.8,12.6,4.3,17.2
				c1.1,2.1,3.1,1.7,4,2.1l11.3-4.1C258.6,116.4,257.1,114.7,258.1,112.4z"
            />
            <path
              className="st9"
              d="M255.7,95.7l3.9,14.6c2.6,4.2,11,3.3,11,3.3l3.8-3.3c0,0-8.8-1.4-10.8-2.7
				C261.5,106.3,255.7,95.7,255.7,95.7z"
            />
            <path
              className="st7"
              d="M261.8,111l-3.9,1.9c-2.6,1.2,0,3,0,3s1.7,0.4,3.2,0c1.5-0.4,1.8-3.9,1.8-3.9l2.1-0.3c2-0.3,1.2-1.1,1.2-1.1
				L261.8,111z"
            />
            <g>
              <g className="st1">
                <g>
                  <path
                    className="st19"
                    d="M281.4,100.6l-15.2,8.8c-0.3,0.2-0.6,0.5-0.7,0.7l-4.2,10.2c-0.1,0.2,0.1,0.3,0.3,0.1l15.2-8.8
							c0.3-0.2,0.6-0.5,0.7-0.7l4.2-10.2C281.8,100.5,281.7,100.4,281.4,100.6z"
                  />
                </g>
                <path
                  className="st42"
                  d="M280.8,100l-15.2,8.8c-0.3,0.2-0.6,0.5-0.7,0.7l-4.2,10.2c0,0.1,0,0.2,0,0.2l0.6,0.6c0,0,0-0.1,0-0.2
						l4.2-10.2c0.1-0.2,0.4-0.6,0.7-0.7l15.2-8.8c0.2-0.1,0.3-0.1,0.3-0.1l-0.6-0.6C281.1,99.9,281,99.9,280.8,100z"
                />
              </g>
            </g>
            <path
              className="st4"
              d="M243.2,87.8c-1,0.3-1.5,1.6-1.2,2.9c0.3,1.4,1.4,2.2,2.4,2c1-0.3,1.5-1.6,1.2-2.9
				C245.3,88.4,244.2,87.5,243.2,87.8z"
            />
            <path
              className="st4"
              d="M246.1,82.7c-1,0.2-1.8,0.8-2.3,1.4c-0.7,0.7-0.8,2.1-0.8,3.1v1.3l1.6,0.4c0,0-0.2-2.4,0-3
				c0.4-1.4,1.3-2.5,2.6-2.8c1.8-0.4,2.5,0,2.5,0S248.2,82.2,246.1,82.7z"
            />
          </g>
          <g>
            <g>
              <polygon
                className="st4"
                points="337.2,108.4 373.5,128.9 373.6,95.4 337.3,74.9 				"
              />
            </g>
            <g>
              <polygon
                className="st13"
                points="339.5,106.2 371.2,124.2 371.3,121.4 339.6,103.5 				"
              />
            </g>
            <g>
              <polygon
                className="st43"
                points="370.3,116.6 370.3,118.2 371,118.6 371,117 371.1,113.3 370.3,112.9 				"
              />
              <polygon
                className="st43"
                points="369.1,112.4 369.1,114.8 369.8,115.2 369.8,112.8 369.8,110 369.1,109.5 				"
              />
              <polygon
                className="st43"
                points="367.9,109.3 367.9,112.1 368.6,112.5 368.6,109.7 368.6,104.8 367.9,104.4 				"
              />
              <polygon
                className="st43"
                points="355.8,98.8 355.8,102.1 356.5,102.6 356.5,94.8 355.8,94.4 				"
              />
              <polygon
                className="st43"
                points="354.6,96.4 354.6,100.5 355.3,100.9 355.3,96.9 355.3,91 354.6,90.6 				"
              />
              <polygon
                className="st43"
                points="366.7,110 366.7,112.9 367.4,113.3 367.4,105.6 366.7,105.2 				"
              />
              <polygon
                className="st43"
                points="365.5,108.5 365.5,111.6 366.2,112 366.2,108.9 366.2,107.3 365.5,106.8 				"
              />
              <polygon
                className="st43"
                points="363.1,106.8 363,109.3 363.8,109.8 363.8,107.2 363.8,105 363.1,104.6 				"
              />
              <polygon
                className="st43"
                points="361.8,104.2 361.8,107.9 362.6,108.3 362.6,104.7 362.6,101.5 361.9,101.1 				"
              />
              <polygon
                className="st43"
                points="348.5,98.5 348.5,101.3 349.2,101.7 349.2,94.9 348.5,94.5 				"
              />
              <polygon
                className="st43"
                points="347.3,94 347.3,98.8 348,99.2 348,94.4 348,88.9 347.3,88.5 				"
              />
              <polygon
                className="st43"
                points="353.4,95.1 353.4,99.1 354.1,99.5 354.1,95.5 354.1,92.7 353.4,92.3 				"
              />
              <polygon
                className="st43"
                points="352.1,99.3 352.1,101.9 352.9,102.3 352.9,99.7 352.9,96.8 352.1,96.4 				"
              />
              <polygon
                className="st43"
                points="360.6,101.1 360.6,105.1 361.4,105.5 361.4,101.5 361.4,98.7 360.6,98.3 				"
              />
              <polygon
                className="st43"
                points="359.4,102.1 359.4,105.1 360.1,105.5 360.2,102.5 360.2,100.1 359.4,99.7 				"
              />
              <polygon
                className="st43"
                points="364.3,109.9 364.3,111.5 365,111.9 365,108.3 364.3,107.9 				"
              />
              <polygon
                className="st43"
                points="357,102.6 357,104.2 357.7,104.6 357.7,99.8 357,99.4 				"
              />
              <polygon
                className="st43"
                points="358.2,104.3 358.2,106.4 358.9,106.8 358.9,104.7 358.9,103.1 358.2,102.7 				"
              />
              <polygon
                className="st43"
                points="350.9,100.4 350.9,102.3 351.6,102.8 351.7,98 350.9,97.6 				"
              />
              <polygon
                className="st43"
                points="349.7,102.2 349.7,103.4 350.4,103.8 350.4,99 349.7,98.6 				"
              />
              <polygon
                className="st43"
                points="346.1,92.6 346.1,96.3 346.8,96.7 346.8,93 346.8,89.9 346.1,89.5 				"
              />
              <polygon
                className="st43"
                points="344.9,95.7 344.9,98.5 345.6,98.9 345.6,96.1 345.6,92.3 344.9,91.9 				"
              />
              <polygon
                className="st43"
                points="343.6,96.3 343.6,98.1 344.4,98.6 344.4,96.7 344.4,93.8 343.7,93.4 				"
              />
              <polygon
                className="st43"
                points="342.4,99.1 342.4,100.5 343.1,101 343.2,96.2 342.4,95.8 				"
              />
              <polygon
                className="st43"
                points="341.2,96.5 341.2,98.5 341.9,98.9 342,95.3 341.2,94.9 				"
              />
              <polygon
                className="st43"
                points="340,93.1 340,95.3 340.7,95.7 340.7,90.5 340,90 				"
              />
            </g>
          </g>
          <g>
            <g className="st1">
              <g>
                <path
                  className="st4"
                  d="M304.6,92.2l-0.2-0.4c0-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0l-0.6-0.3l0,0l-0.6-0.5c0,0,0,0,0,0
						c-0.1,0-0.2,0-0.2,0l-0.2,0.2l-0.6,0.8c-0.1,0.1,0,0.4,0.2,0.5l1.3,0.8l0.2,0.1l1.3,0.8c0.2,0.1,0.3-0.1,0.2-0.3L304.6,92.2z"
                />
              </g>
              <g>
                <path
                  className="st44"
                  d="M298.2,87.8l10.7,6.2l0-7.5l-10.7-6.2L298.2,87.8z M303.6,84.1c1.5,0.9,2.7,2.9,2.7,4.6
						c0,1.7-1.2,2.4-2.7,1.5c-1.5-0.9-2.7-2.9-2.7-4.6C300.9,83.9,302.1,83.2,303.6,84.1z"
                />
              </g>
              <g>
                <polygon
                  className="st4"
                  points="302.5,87.9 304.8,87.9 302.5,85.2 					"
                />
              </g>
              <g>
                <path
                  className="st44"
                  d="M294.5,93.1l18,10.4l0.1-20.7l-18-10.4L294.5,93.1z M297.6,79.6c0-0.2,0.1-0.3,0.3-0.2l11.4,6.6
						c0.2,0.1,0.3,0.4,0.3,0.6l0,8.2c0,0.2-0.2,0.3-0.3,0.2l-4.7-2.7l0.6,1.4c0.1,0.2,0,0.4-0.2,0.3l-1.3-0.8l-0.2-0.1l-1.3-0.8
						c-0.2-0.1-0.3-0.4-0.2-0.5l0.6-0.8l-4.6-2.7c-0.2-0.1-0.3-0.4-0.3-0.6L297.6,79.6z"
                />
              </g>
              <g>
                <path
                  className="st44"
                  d="M303.6,84.8c-1.1-0.6-2-0.1-2,1.2c0,1.3,0.9,2.8,2,3.5c1.1,0.6,2,0.1,2-1.2
						C305.6,87.1,304.7,85.5,303.6,84.8z M302.5,87.9l0-2.7l2.3,2.7L302.5,87.9z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M303.6,84.1c-1.5-0.9-2.7-0.2-2.7,1.5c0,1.7,1.2,3.8,2.7,4.6c1.5,0.9,2.7,0.2,2.7-1.5
						C306.2,87,305.1,84.9,303.6,84.1z M303.6,89.5c-1.1-0.6-2-2.2-2-3.5c0-1.3,0.9-1.8,2-1.2c1.1,0.6,2,2.2,2,3.5
						C305.6,89.6,304.7,90.1,303.6,89.5z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M297.9,79.4c-0.2-0.1-0.3,0-0.3,0.2l0,8.2c0,0.2,0.1,0.5,0.3,0.6l4.6,2.7l0.2-0.2c0-0.1,0.1-0.1,0.2,0
						l0.6,0.5l0,0l0.7,0.3c0.1,0,0.2,0.1,0.2,0.2l0.2,0.4l4.7,2.7c0.2,0.1,0.3,0,0.3-0.2l0-8.2c0-0.2-0.1-0.5-0.3-0.6L297.9,79.4z
						 M308.9,94l-10.7-6.2l0-7.5l10.7,6.2L308.9,94z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M306.6,65.5l0,0.5l-0.5-0.3l0-0.5l-4.8-2.8l0,0.3l-0.5-0.3l0-0.3l-0.6-0.3c-0.1-0.1-0.3,0-0.3,0.1l0,8
						c0,0.2,0.1,0.4,0.3,0.5l7,4c0.1,0.1,0.3,0,0.3-0.2l0-8c0-0.2-0.1-0.4-0.3-0.5L306.6,65.5z M306.8,73.7l-6.5-3.7l0-7.4l6.5,3.7
						L306.8,73.7z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M304.5,67.2c0-0.6-0.4-1.2-0.9-1.5c-0.5-0.3-0.9-0.1-0.9,0.5c0,0.4,0.2,0.8,0.4,1.1l-0.6,2.8l0.9,0.5
						l0.1,0.1l0.9,0.5l-0.5-3.4C304.3,67.8,304.5,67.5,304.5,67.2z"
                />
              </g>
              <g>
                <path
                  className="st45"
                  d="M300.4,70l6.5,3.7l0-7.4l-6.5-3.7L300.4,70z M302.7,66.1c0-0.6,0.4-0.8,0.9-0.5c0.5,0.3,0.9,1,0.9,1.5
						c0,0.4-0.2,0.6-0.4,0.6l0.5,3.4l-0.9-0.5l-0.1-0.1l-0.9-0.5l0.6-2.8C302.9,66.9,302.7,66.5,302.7,66.1z"
                />
              </g>
              <g>
                <path
                  className="st45"
                  d="M303.9,58.4l-0.6-0.3c-1.2-0.7-2.1-0.1-2.1,1.2l0,3.2l4.8,2.8l0-3.2C306,60.7,305.1,59.1,303.9,58.4z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M303.9,57.8l-0.6-0.3c-1.5-0.8-2.6-0.2-2.6,1.5l0,3.2l0,0.3l0.5,0.3l0-0.3l0-3.2c0-1.3,1-1.9,2.1-1.2
						l0.6,0.3c1.2,0.7,2.1,2.3,2.1,3.6l0,3.2l0,0.5l0.5,0.3l0-0.5l0-3.2C306.6,60.7,305.4,58.6,303.9,57.8z"
                />
              </g>
              <g>
                <path
                  className="st45"
                  d="M294.6,70.2l18,10.4l0.1-20.7l-18-10.4L294.6,70.2z M299.9,61.9c0-0.2,0.1-0.2,0.3-0.1l0.6,0.3l0-3.2
						c0-1.7,1.2-2.3,2.6-1.5l0.6,0.3c1.5,0.8,2.6,2.9,2.6,4.6l0,3.2l0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5l0,8c0,0.2-0.1,0.2-0.3,0.2
						l-7-4c-0.1-0.1-0.3-0.3-0.3-0.5L299.9,61.9z"
                />
              </g>
            </g>
            <g className="st1">
              <g>
                <path
                  className="st4"
                  d="M322.5,104.9c0,0.4,0.4,0.9,0.8,1.1c0.4,0.2,0.7,0.1,0.8-0.2c0,0,0-0.1,0-0.1l-1.6-0.9
						C322.5,104.8,322.5,104.9,322.5,104.9z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M322.1,104c-0.2-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.4,0.2c0,0,0-0.1,0-0.1l1.6,0.9
						c0,0,0,0.1,0,0.1l0.4,0.2c0.2,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5L322.1,104z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M322,102.7c-0.2-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l2.6,1.5c0.2,0.1,0.3,0,0.3-0.2
						c0-0.2-0.1-0.4-0.3-0.5L322,102.7z"
                />
              </g>
              <g>
                <path
                  className="st9"
                  d="M314.3,104.4l18,10.4l0.1-20.7l-18-10.4L314.3,104.4z M324.5,106l-0.4-0.2c-0.1,0.4-0.4,0.5-0.8,0.2
						c-0.4-0.2-0.7-0.7-0.8-1.1l-0.4-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.3,0.3-0.2l2.4,1.4c0.2,0.1,0.3,0.3,0.3,0.5
						C324.8,106.1,324.6,106.1,324.5,106z M324.6,104.9l-2.6-1.5c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.3,0.3-0.2l2.6,1.5
						c0.2,0.1,0.3,0.3,0.3,0.5C324.9,104.9,324.7,105,324.6,104.9z M320.1,93.7c0.3-1.3,1.3-1.9,2.6-1.5c0.2,0.1,0.4,0.2,0.7,0.3
						c1.8,1,3.3,3.6,3.3,5.6c0,0.6-0.1,1-0.3,1.4c-0.5,0.9-0.9,1.8-1.2,2.9l-0.3,1.2l-1-0.6l-0.4-0.2l-1.6-0.9l-0.4-1.9
						c-0.2-1.2-0.6-2.5-1-3.7C320.1,95.4,320,94.5,320.1,93.7z"
                />
              </g>
              <g>
                <path
                  className="st9"
                  d="M323.3,93.1c-0.2-0.1-0.4-0.2-0.6-0.3c-1.1-0.4-1.9,0.1-2.2,1.2c-0.1,0.6,0,1.4,0.2,2.2
						c0.4,1.1,0.8,2.5,1.1,3.9l0.3,1.4l1.2,0.7l0.4,0.2l0.6,0.3l0.2-0.8c0.3-1.2,0.8-2.1,1.2-3c0.2-0.3,0.3-0.7,0.3-1.2
						C326.1,96.2,324.8,94,323.3,93.1z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M323.3,92.6c-0.2-0.1-0.4-0.2-0.7-0.3c-1.3-0.5-2.3,0.1-2.6,1.5c-0.1,0.8,0,1.7,0.3,2.6
						c0.4,1.2,0.8,2.5,1,3.7l0.4,1.9l1.6,0.9l0.4,0.2l1,0.6l0.3-1.2c0.3-1.1,0.7-2,1.2-2.9c0.2-0.4,0.3-0.8,0.3-1.4
						C326.6,96.1,325.1,93.6,323.3,92.6z M325.8,99.1c-0.5,0.8-0.9,1.8-1.2,3l-0.2,0.8l-0.6-0.3l-0.4-0.2l-1.2-0.7l-0.3-1.4
						c-0.3-1.4-0.7-2.8-1.1-3.9c-0.3-0.8-0.4-1.5-0.2-2.2c0.2-1.1,1.1-1.6,2.2-1.2c0.2,0.1,0.4,0.2,0.6,0.3c1.5,0.9,2.8,3,2.7,4.8
						C326.1,98.4,326,98.8,325.8,99.1z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M322.6,72.9l0,7.1l0,1c0,0.2,0.1,0.4,0.3,0.5c0.1,0.1,0.3,0,0.3-0.2l0-1l0-7.1c0-0.2-0.1-0.4-0.3-0.5
						C322.8,72.7,322.6,72.8,322.6,72.9z"
                />
              </g>
              <g>
                <path
                  className="st13"
                  d="M323.8,80.1l0-9.2c0-0.6-0.4-1.2-0.9-1.5c-0.5-0.3-0.9-0.1-0.9,0.5l0,9.2c0,0.1,0,0.2-0.1,0.2
						c-0.3,0.1-0.5,0.5-0.5,1c0,1,0.7,2.1,1.5,2.6c0.8,0.5,1.5,0.1,1.5-0.9c0-0.5-0.2-1.1-0.5-1.6C323.8,80.3,323.8,80.2,323.8,80.1
						z M322.9,82.2c-0.5-0.3-0.9-1-0.9-1.5c0-0.5,0.3-0.7,0.6-0.6l0-7.1c0-0.2,0.1-0.2,0.3-0.1c0.1,0.1,0.3,0.3,0.3,0.5l0,7.1
						c0.4,0.3,0.6,0.9,0.6,1.3C323.8,82.3,323.4,82.5,322.9,82.2z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M323.2,81.3c0,0.2-0.1,0.2-0.3,0.2c-0.1-0.1-0.3-0.3-0.3-0.5l0-1c-0.4-0.1-0.6,0.2-0.6,0.6
						c0,0.6,0.4,1.2,0.9,1.5c0.5,0.3,0.9,0.1,0.9-0.5c0-0.5-0.3-1-0.6-1.3L323.2,81.3z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,78.4l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.2,0.1l1.3,0.7
						c0.1,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5L324.3,78.4z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,75.2l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.2,0.1l1.3,0.7
						c0.1,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5L324.3,75.2z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,72l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.2,0.1l1.3,0.7c0.1,0.1,0.3,0,0.3-0.2
						c0-0.2-0.1-0.4-0.3-0.5L324.3,72z"
                />
              </g>
              <g>
                <path
                  className="st13"
                  d="M314.3,75.8l0,5.7l18,10.4l0.1-20.7l-18-10.4L314.3,75.8z M321.5,78.7l0-9.1c0-0.9,0.6-1.3,1.4-0.8
						c0.8,0.4,1.4,1.5,1.4,2.4l0,0.8l1.3,0.7c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.2-0.3,0.2l-1.3-0.7l0,1l1.3,0.7
						c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.2-0.3,0.2l-1.3-0.7l0,1l1.3,0.7c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.2-0.3,0.2l-1.3-0.7
						l0,1l1.3,0.7c0.1,0.1,0.3,0.3,0.3,0.5c0,0.2-0.1,0.2-0.3,0.2l-1.3-0.7l0,1l1.3,0.7c0.1,0.1,0.3,0.3,0.3,0.5
						c0,0.2-0.1,0.2-0.3,0.2l-1.3-0.7l0,1.3c0.4,0.7,0.6,1.4,0.6,2.1c0,1.3-0.9,1.8-2.1,1.2c-1.1-0.7-2.1-2.2-2-3.5
						C320.8,79.3,321.1,78.9,321.5,78.7z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,76.8l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.2,0.1l1.3,0.7
						c0.1,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5L324.3,76.8z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,73.6l-0.2-0.1c-0.1-0.1-0.3,0-0.3,0.2c0,0.2,0.1,0.4,0.3,0.5l0.2,0.1l1.3,0.7
						c0.1,0.1,0.3,0,0.3-0.2c0-0.2-0.1-0.4-0.3-0.5L324.3,73.6z"
                />
              </g>
              <g>
                <path
                  className="st4"
                  d="M324.3,79l-0.2-0.1c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.2,0.3-0.2l0.2,0.1l0-1l-0.2-0.1
						c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.2,0.3-0.2l0.2,0.1l0-1l-0.2-0.1c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.2,0.3-0.2
						l0.2,0.1l0-1l-0.2-0.1c-0.1-0.1-0.3-0.3-0.3-0.5c0-0.2,0.1-0.2,0.3-0.2l0.2,0.1l0-1l-0.2-0.1c-0.1-0.1-0.3-0.3-0.3-0.5
						c0-0.2,0.1-0.2,0.3-0.2l0.2,0.1l0-0.8c0-0.9-0.6-2-1.4-2.4c-0.8-0.5-1.4-0.1-1.4,0.8l0,9.1c-0.4,0.2-0.6,0.7-0.6,1.3
						c0,1.3,0.9,2.9,2,3.5c1.1,0.7,2.1,0.1,2.1-1.2c0-0.6-0.2-1.4-0.6-2.1L324.3,79z M324.4,82.1c0,1-0.7,1.3-1.5,0.9
						c-0.8-0.5-1.5-1.7-1.5-2.6c0-0.5,0.2-0.9,0.5-1c0.1,0,0.1-0.1,0.1-0.2l0-9.2c0-0.6,0.4-0.8,0.9-0.5c0.5,0.3,0.9,1,0.9,1.5
						l0,9.2c0,0.1,0,0.2,0.1,0.3C324.2,80.9,324.4,81.5,324.4,82.1z"
                />
              </g>
            </g>
          </g>
          <g>
            <path
              className="st4"
              d="M276.1,41.2c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.1-0.4-0.1l-8.8,3.1c-0.2,0.1-0.3,0.5-0.1,0.9
				c0.1,0.2,0.3,0.4,0.4,0.5c0.1,0.1,0.3,0.1,0.4,0.1l0.7-0.2l0,10.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.4,0.4l14.2,8.2
				c0.1,0.1,0.3,0.1,0.4,0c0.1-0.1,0.2-0.2,0.2-0.4l0-10.8l0.7,1c0.1,0.2,0.2,0.3,0.4,0.3c0.2,0.1,0.3,0.1,0.4,0
				c0.2-0.2,0.2-0.6-0.1-1L276.1,41.2z M276.8,60.1l-2.5-1.4l0-6.8l2.5,1.4L276.8,60.1z M282.1,63.3l-4.1-2.4l0-8.3l-4.8-2.8l0,0.6
				l0,7.6v0l-4.1-2.4l0-11l6.6-2.4l6.4,9.7L282.1,63.3z"
            />
            <path
              className="st4"
              d="M275.6,37.5c-1.1-0.6-2.2-0.8-3.1-0.3c-0.2,0.1-0.2,0.5,0,0.9c0.1,0.2,0.3,0.3,0.4,0.4
				c0.1,0.1,0.3,0.1,0.4,0c0.6-0.4,1.4-0.2,2.3,0.2c0.8,0.5,1.6,1.3,2.2,2.4c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.4,0
				c0.2-0.1,0.2-0.5,0-0.9C277.8,39.3,276.7,38.2,275.6,37.5z"
            />
            <path
              className="st4"
              d="M275.6,34.7c-1.8-1-3.5-1.3-4.9-0.5c-0.2,0.1-0.2,0.5,0,0.9c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.4,0
				c1.1-0.6,2.6-0.4,4,0.4c1.5,0.8,2.9,2.3,4,4.2c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.4,0c0.2-0.1,0.2-0.5,0-0.9
				C279.1,37.4,277.4,35.7,275.6,34.7z"
            />
            <path
              className="st4"
              d="M275.7,31.8c-2.4-1.4-4.8-1.7-6.6-0.7c-0.2,0.1-0.2,0.5,0,0.9c0.1,0.2,0.3,0.3,0.4,0.4
				c0.1,0.1,0.3,0.1,0.4,0c1.6-0.9,3.7-0.6,5.8,0.6c2.1,1.2,4.2,3.3,5.8,6.1c0.1,0.2,0.3,0.3,0.4,0.4c0.1,0.1,0.3,0.1,0.4,0
				c0.2-0.1,0.2-0.5,0-0.9C280.4,35.6,278,33.2,275.7,31.8z"
            />
          </g>
          <g>
            <g>
              <path
                className="st4"
                d="M274.3,100.1c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C275.3,100.6,274.9,100.1,274.3,100.1z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M274.3,91.2c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-0.1C275.3,91.6,274.9,91.2,274.3,91.2z
					"
              />
              <path
                className="st4"
                d="M274.3,95.7c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-0.1C275.3,96.1,274.9,95.7,274.3,95.7z
					"
              />
              <path
                className="st4"
                d="M274.3,86.7c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-0.1C275.3,87.2,274.9,86.7,274.3,86.7z
					"
              />
              <path
                className="st4"
                d="M274.3,82.3c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.4,1-1v-0.1C275.3,82.7,274.9,82.3,274.3,82.3z
					"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M274.8,78c-0.5-0.3-1.1-0.1-1.4,0.3c-0.1,0.2-0.2,0.5-0.1,0.7c0.1,0.5,0.5,0.8,1,0.8c0.6,0,1-0.5,1-1
					C275.3,78.5,275.1,78.1,274.8,78z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M254.7,65.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C255.4,66.8,255.2,66.2,254.7,65.9z"
              />
              <path
                className="st4"
                d="M251.4,63.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C252.1,64.8,251.9,64.2,251.4,63.9z"
              />
              <path
                className="st4"
                d="M248,61.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C248.7,62.8,248.5,62.2,248,61.9z"
              />
              <path
                className="st4"
                d="M271.4,76c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C272.1,76.9,271.9,76.2,271.4,76z"
              />
              <path
                className="st4"
                d="M244.7,59.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C245.4,60.8,245.2,60.2,244.7,59.9z"
              />
              <path
                className="st4"
                d="M264.8,71.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C265.4,72.9,265.2,72.2,264.8,71.9z"
              />
              <path
                className="st4"
                d="M268.1,73.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C268.8,74.9,268.6,74.2,268.1,73.9z"
              />
              <path
                className="st4"
                d="M261.4,69.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C262.1,70.9,261.9,70.2,261.4,69.9z"
              />
              <path
                className="st4"
                d="M258.1,67.9c-0.5-0.3-1.1-0.1-1.4,0.3c-0.3,0.5-0.1,1.1,0.3,1.4l0.1,0c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.8-0.5C258.7,68.9,258.5,68.2,258.1,67.9z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M240.9,57.8c-0.6,0-1,0.5-1,1c0,0.3,0.2,0.7,0.5,0.9l0,0c0.2,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.9-0.5
					c0.1-0.2,0.2-0.5,0.1-0.7C241.8,58.1,241.4,57.8,240.9,57.8z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M240.9,49.9c-0.6,0-1,0.4-1,1V51c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,50.4,241.4,49.9,240.9,49.9z"
              />
              <path
                className="st4"
                d="M240.9,46c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,46.4,241.4,46,240.9,46z"
              />
              <path
                className="st4"
                d="M240.9,42.1c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,42.5,241.4,42.1,240.9,42.1z"
              />
              <path
                className="st4"
                d="M240.9,53.8c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,54.3,241.4,53.8,240.9,53.8z"
              />
              <path
                className="st4"
                d="M240.9,30.3c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,30.8,241.4,30.3,240.9,30.3z"
              />
              <path
                className="st4"
                d="M240.9,26.4c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,26.9,241.4,26.4,240.9,26.4z"
              />
              <path
                className="st4"
                d="M240.9,34.2c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,34.7,241.4,34.2,240.9,34.2z"
              />
              <path
                className="st4"
                d="M240.9,38.2c-0.6,0-1,0.4-1,1v0.1c0,0.6,0.4,1,1,1c0.6,0,1-0.5,1-1C241.9,38.6,241.4,38.2,240.9,38.2z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M241.4,22.7c-0.3-0.2-0.7-0.2-1,0c-0.3,0.2-0.5,0.5-0.5,0.9v0.1c0,0.6,0.4,1,1,1c0,0,0.1,0,0.1,0
					c0.3,0,0.6-0.2,0.8-0.5C242,23.6,241.9,23,241.4,22.7z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M247.8,26.6L247.8,26.6c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.9-0.5C248.4,27.5,248.2,26.9,247.8,26.6z"
              />
              <path
                className="st4"
                d="M244.6,24.6L244.6,24.6c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.9-0.5C245.2,25.5,245.1,24.9,244.6,24.6z"
              />
              <path
                className="st4"
                d="M254.1,30.4L254.1,30.4c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.9-0.5C254.7,31.3,254.5,30.7,254.1,30.4z"
              />
              <path
                className="st4"
                d="M250.9,28.5L250.9,28.5c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.9-0.5C251.5,29.4,251.4,28.8,250.9,28.5z"
              />
            </g>
            <g>
              <path
                className="st4"
                d="M257.2,32.4L257.2,32.4c-0.5-0.3-1.1-0.2-1.4,0.3c-0.3,0.5-0.1,1.1,0.4,1.4c0.2,0.1,0.3,0.1,0.5,0.1
					c0.3,0,0.7-0.2,0.9-0.5C257.8,33.3,257.7,32.6,257.2,32.4z"
              />
            </g>
          </g>
        </g>
        <g>
          <path
            className="st46"
            d="M373,185.7c-1.6-0.3-4.2,0.8-5.8,1c-4.9,0.8-9.8,1.7-14.8,2.5c-0.6,0.1-1.2,0.2-1.6,0.5
			c-0.5,0.3-0.8,0.9-0.6,1.5c0.2,0.7,1.1,0.9,1.9,1c8,0.7,16.2-0.7,23.5-4C376.8,187.6,373.5,185.8,373,185.7z"
          />
          <g>
            <g>
              <g>
                <path
                  className="st47"
                  d="M360.7,121.2c-2-0.6-3.4,0.9-3.6,1.8c-0.2,0.6-1.7,12.6-1.9,14.1c0,0.1,0,0.2-0.1,0.3l-4,9.2
						c0,0.1-0.1,0.2-0.2,0.3l-1.2,1.6c-0.1,0.2-0.2,0.4-0.3,0.7l-0.3,2.3c0,0,0.2,0.1,0.4,0c0,0.1-0.1,0.1-0.1,0.1l0.1,1.9
						c0,0,0.3,0.2,0.5-0.3l0.3,0.8c0.4,0,0.5-0.5,0.4-0.9l0.2,0.6c0.7,0,0.4-1.3,0.4-1.3l0.1-0.2l0.3,1.1c0.5,0.2,0.4-0.9,0.4-0.9
						l-0.1-1c0,0,0-0.1,0-0.1l0-0.3c0-0.2,0-0.5,0.2-0.7c0.3-0.6,0.7-1.6,0.7-2.3c0-0.3,0-0.6,0.1-0.9l5.1-8.9
						c0.1-0.1,0.1-0.2,0.1-0.3c0.3-1.1,1.4-7.9,2.4-11.3c0.5-1.8,0.8-2.9,1-3.7C361.8,122.2,361.4,121.4,360.7,121.2z"
                />
                <path
                  className="st48"
                  d="M360.7,121.2C360.6,121.2,360.6,121.2,360.7,121.2c-1-0.3-2,0.5-1.9,1.5c0,0.2,0,0.3,0,0.5
						c-0.4,2.4-1.3,4.6-2,6.9c-0.7,2.3,0,4.7-0.5,7c-0.5,2-4.4,9.5-5.3,11c-0.7,1.1-1.1,2.3-1.4,3.5c0.1,0,0.1,0,0.2-0.1
						c0,0.1-0.1,0.1-0.1,0.1l0.1,1.9c0,0,0.3,0.2,0.5-0.3l0.3,0.8c0.4,0,0.5-0.5,0.4-0.9l0.2,0.6c0.7,0,0.4-1.3,0.4-1.3l0.1-0.2
						l0.3,1.1c0.5,0.2,0.4-0.9,0.4-0.9l-0.1-1c0,0,0-0.1,0-0.1l0-0.3c0-0.2,0-0.5,0.2-0.7c0.3-0.6,0.7-1.6,0.7-2.3
						c0-0.3,0-0.6,0.1-0.9l5.1-8.9c0.1-0.1,0.1-0.2,0.1-0.3c0.3-1.1,1.4-7.9,2.4-11.3c0.5-1.8,0.8-2.9,1-3.7
						C361.8,122.2,361.4,121.4,360.7,121.2z"
                />
              </g>
              <path
                className="st19"
                d="M354.5,184.8c-0.4,1.7-3.1,3.8-4.1,4.6c-1,0.7-1.2,1.1-1.2,1.1c0,0.4,0.3,0.6,0.3,0.6
					c0.2,0.2,1.8,0.6,2.8,0.2c1-0.4,2-1.5,3.4-1.8c1.5-0.3,1.8-0.7,1.8-0.7c0.4-1.5-0.3-3.7-0.3-3.7L354.5,184.8z"
              />
              <path
                className="st13"
                d="M355,189c-0.9,0.4-1.6,1.1-2.5,1.5c-1.1,0.5-2.3,0.6-3.3-0.1c0,0.1-0.1,0.1-0.1,0.1c0,0.4,0.3,0.6,0.3,0.6
					c0.2,0.2,1.8,0.6,2.8,0.2c1-0.4,2-1.5,3.4-1.8c1.5-0.3,1.8-0.7,1.8-0.7c0.1-0.2,0.1-0.5,0.1-0.7c0,0,0,0-0.1,0.1
					C356.8,188.6,355.9,188.6,355,189z"
              />
              <path
                className="st13"
                d="M353.2,186.9l-0.2,0.2c0.8,0.1,1.4,0.7,1.4,0.7l0.2-0.2C353.9,186.9,353.2,186.9,353.2,186.9z"
              />
              <path
                className="st13"
                d="M352.8,187.4l-0.2,0.2c0.8,0.1,1.4,0.7,1.4,0.7l0.2-0.2C353.5,187.3,352.8,187.4,352.8,187.4z"
              />
              <path
                className="st13"
                d="M352.3,187.9l-0.2,0.2c0.8,0.1,1.4,0.7,1.4,0.7l0.2-0.2C353,187.8,352.3,187.9,352.3,187.9z"
              />
              <path
                className="st19"
                d="M373.8,181l-2.2,1.5l0.3,5.2c-0.4,1.4-0.8,1.9-0.3,2.2c0.8,0.5,2.6-0.2,3.4-1.9c0.4-0.8,1.2-5.2,0.8-5.7
					C375.4,181.8,373.8,181,373.8,181z"
              />
              <path
                className="st13"
                d="M375.6,182c-0.1,3.1-0.1,4.9-1.4,6.5c-0.6,0.8-1.4,0.8-2.4,1c-0.1,0-0.2,0-0.3,0c0,0.2,0.1,0.3,0.3,0.4
					c0.8,0.5,2.6-0.2,3.4-1.9c0.4-0.8,1.2-5.2,0.8-5.7C375.8,182.2,375.7,182.1,375.6,182z"
              />
              <path
                className="st13"
                d="M371.8,184.8l0,0.4c0,0,0.9,0.2,1.5,0v-0.4C373.2,184.8,372.2,185,371.8,184.8z"
              />
              <path
                className="st13"
                d="M371.8,185.5l0,0.3c0,0,0.6,0.1,1.4,0v-0.3C373.2,185.5,372.7,185.7,371.8,185.5z"
              />
              <path
                className="st13"
                d="M371.8,186.2l0,0.4c0,0,0.5,0.2,1.4,0v-0.4C373.2,186.2,372.7,186.4,371.8,186.2z"
              />
              <path
                className="st13"
                d="M370.3,173.9c-0.9-2-2-3.6-2.8-4.5l1.4-13.2c0,0,0.5-3.1,0.5-4.2c0-5-1.3-4.4-1.3-4.4l-10.1,1.6l3.1,7.7
					c0,0,0.9,8.6,1.6,13.2c-0.1,0.6,0.1,1.4,0.4,1.8c3.4,4.2,8.7,10.9,8.7,10.9s0.3,0.5,1.6,0c1.1-0.4,0.9-1.7,0.9-1.7
					S372.2,178.2,370.3,173.9z"
              />
              <path
                className="st13"
                d="M356.4,145.6c-2.3,11.4-3.1,16.3-3.6,19.7c-0.1,0.4,0,0.9,0.1,1.4c0.1,1.2,0.3,3,0.5,4.1
					c1.4,11.6,1.1,14.2,1.1,14.2s0.3,0.5,1.7,0.4c1-0.1,1.3-0.5,1.3-0.5s0.7-7.1,0.7-9.5c0-2-0.7-6.5-0.8-7.7l4.3-12.7L356.4,145.6z
					"
              />
              <path
                className="st49"
                d="M355.1,170c-0.4-3.9-0.1-7.4,0.4-11.3c0.4-3,0.7-6.1,1.3-9c0-0.1,0-0.3,0-0.4c0.1-0.9,0.1-1.8,0.2-2.6
					l-0.5-1c-2.3,11.4-3.1,16.3-3.6,19.7c-0.1,0.4,0,0.9,0.1,1.4c0.1,1.2,0.3,3,0.5,4.1c1.4,11.6,1.1,14.2,1.1,14.2s0.3,0.5,1.7,0.4
					c0.4,0,0.7-0.1,1-0.2c-0.7-1.4-1.2-2.9-1.4-4.5C355.3,177.2,355.5,173.6,355.1,170z"
              />
              <path
                className="st49"
                d="M369.7,178.6c-1.5-2.1-3.7-4-5-6.3c-1.1-2-1.3-4.3-1.8-6.5c-0.6-2.9-0.9-5.8-1.4-8.8
					c-0.3-0.3-1.6-1.3-2-1.6l1.4,1.6c0,0,0.9,8.6,1.6,13.2c-0.1,0.6,0.1,1.4,0.4,1.8c3.4,4.2,8.7,10.9,8.7,10.9s0.3,0.5,1.6,0
					c0.1,0,0.1,0,0.2-0.1C372.1,181.6,370.9,180.2,369.7,178.6z"
              />
              <path
                className="st19"
                d="M370.4,125.9c-0.6-1.3-2.4-2.9-4.3-3.7c-2.5-1.1-5.2-1.2-6.6-1.1c-0.7,0.1-0.9,0.2-0.9,0.2
					s-0.8,0.2-1.1,1.4c-0.1,0.5,0,1.6-0.2,2.4l-1.1,2.7l-1.1,3.1c-0.6,2.5,1.1,3.8,1.1,3.8l0.9,6l-1.7,7.5c0,0,8.3,4.8,13.8,0.5
					l-1.5-7c-0.1-0.3-0.1-0.6-0.1-0.8C368.2,133.7,371.9,129.1,370.4,125.9z"
              />
              <path
                className="st48"
                d="M358.2,140.3c-0.3-1.7-0.5-3.3-0.5-5c-1.6-1.8-2-3.9-1.9-6.1l-0.6,1.7c-0.6,2.5,1.1,3.8,1.1,3.8l0.9,6
					l-1.7,7.5c0,0,1.2,0.7,3,1.3c-0.1-0.3-0.2-0.7-0.3-1C357.8,145.9,358.2,143,358.2,140.3z"
              />
              <path
                className="st47"
                d="M363.1,120.8c-2.6,0.5-3.1,2.5-2.8,2.9s1,1.3,3.1,0.7c2.5-0.8,2.6-2.1,2.6-2.1S364.8,120.5,363.1,120.8z"
              />
              <path
                className="st47"
                d="M365.2,119.3l-4.3-0.5c0,0-0.8,3.3-0.6,4.9l5.7-1.5C365.9,122.2,365,120.6,365.2,119.3z"
              />
              <path
                className="st50"
                d="M360.2,123.7c2.8,2.4,4.7-3.2,4.7-3.2s0,0,0.2-1.2l-4.3-0.5C360.9,118.9,359,122.7,360.2,123.7z"
              />
              <path
                className="st47"
                d="M364.6,113.7l-8,1.4l1.6,5.2c0.8,2.2,2.5,4,4.4,2.7c0,0,3.3-2.4,2.9-4.9L364.6,113.7z"
              />
              <path
                className="st22"
                d="M359.4,110.6c-5.4,2.1-2.9,6.4-2.9,6.4c3.7,1.2,6.8-0.3,6.8-0.3l1.6,3.8c0.3,0.7,1,1.7,1,1.7l2,0.9
					c0.3,0.3-0.6-5.7-1.4-8.5C365.9,112.5,363.1,109.2,359.4,110.6z"
              />
              <g>
                <path
                  className="st51"
                  d="M355.1,139.1c-0.7,0.1-1.8,0.2-1.8,0.2s-0.3,0.6-0.3,0.6c0.8,0.9,3.6,3.3,3.6,3.3l1.4-2.2l-1.8-1.4
						C356.2,139.5,355.8,139,355.1,139.1z"
                />
                <path
                  className="st52"
                  d="M355.1,139.1c-0.7,0.1-1.8,0.2-1.8,0.2s-0.2,0-0.2,0.1c0,0-0.1,0.3-0.1,0.3s0,0.1,0.1,0.2
						c0,0-0.1-0.1,0.1-0.1c0.4,0,1.2-0.1,1.7-0.2c0.4,0,0.8,0.1,1.1,0.3c0.1,0,1.8,1.5,1.8,1.5l0.2-0.5l-1.8-1.4
						C356.2,139.5,355.8,139,355.1,139.1z"
                />
                <path
                  className="st47"
                  d="M373.8,138.9L373.8,138.9c-0.4-2.1-1.2-6-2-8.6c-0.6-2-1.1-3.3-1.4-4c-0.3-0.7-1.1-1.1-1.9-0.9
						c-1.9,0.5-2.1,3.3-1.9,3.8c0.1,0.5,2.3,6.8,3.4,9.9c-3.8,1-8.3,2.5-8.3,2.5l-0.1,0l-1.8-0.9l-1.3-0.5c-0.2-0.1-0.3-0.1-0.5-0.1
						l-1.3,0c0,0,0.4,0.3,0.5,0.5c0,0,0.2,0.3,0.2,0.3c0,0.1,0,0.4-0.1,0.6c0,0-0.3,0.3-1.1,0.4c-0.8,0.1-1.5,0-1.5,0s0,0.7,1.1,0.9
						c0,0,0.1,0.6,0.7,0.7c0,0,0.3,0.6,0.9,0.6c0,0,0.5,0.4,1.1,0.4c0,0,1.7,0,3-0.7l1.2-0.1l9.6-1c1-0.1,1.8-0.6,1.8-1.7l-0.3-1.3
						C373.9,139.3,373.8,139.1,373.8,138.9z"
                />
                <path
                  className="st48"
                  d="M366.7,127.6c-0.1,0.7-0.1,1.3-0.1,1.5c0.1,0.5,2.3,6.6,3.4,9.7c0.3,0,0.8-1.5-0.5-5.1
						C368.6,131.6,366.8,129.7,366.7,127.6z"
                />
                <path
                  className="st48"
                  d="M355.6,141.6c-0.5,0-0.8,0-0.8,0s0,0.7,1.1,0.9c0,0,0.1,0.6,0.7,0.7c0,0,0.3,0.6,0.9,0.6
						c0,0,0.5,0.4,1.1,0.4c0,0,1.7,0,3-0.7l0.1,0c-0.5-0.1-1-0.2-1.5-0.3C358.5,143.1,356.8,142.6,355.6,141.6z"
                />
              </g>
              <path
                className="st48"
                d="M359.6,134.7c0,0,4,2.9,6.4-0.7C366,134,362.9,136.1,359.6,134.7z"
              />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g className="st1">
              <g>
                <polygon
                  className="st8"
                  points="305.2,178 328.6,191.6 352.3,178 328.8,164.3 					"
                />
              </g>
              <g>
                <polygon
                  className="st31"
                  points="305.2,178 305.2,202.5 328.7,216.1 328.6,191.6 					"
                />
              </g>
              <g>
                <polygon
                  className="st53"
                  points="328.6,191.6 328.7,216.1 352.3,202.5 352.3,178 					"
                />
              </g>
            </g>
          </g>
          <g>
            <g className="st1">
              <g>
                <path
                  className="st4"
                  d="M340.2,175l-10.1-5.8c-0.8-0.4-2-0.4-2.7,0l-10,5.8c-0.7,0.4-0.7,1.1,0,1.6l10.1,5.8c0.7,0.4,2,0.4,2.7,0
						l10-5.8C340.9,176.1,340.9,175.4,340.2,175z"
                />
              </g>
              <path
                className="st19"
                d="M340.2,176.6l-10,5.8c-0.7,0.4-2,0.4-2.7,0l-10.1-5.8c-0.4-0.2-0.6-0.5-0.6-0.8l0,3.2
					c0,0.3,0.2,0.6,0.6,0.8l10.1,5.8c0.7,0.4,2,0.4,2.7,0l10-5.8c0.4-0.2,0.6-0.5,0.6-0.8l0-3.2
					C340.7,176.1,340.5,176.3,340.2,176.6z"
              />
              <path
                className="st16"
                d="M340.2,176.6l-10,5.8c-0.7,0.4-2,0.4-2.7,0l-10.1-5.8c-0.4-0.2-0.6-0.5-0.6-0.8l0,0.8
					c0,0.3,0.2,0.6,0.6,0.8l10.1,5.8c0.7,0.4,2,0.4,2.7,0l10-5.8c0.4-0.2,0.6-0.5,0.6-0.8l0-0.8
					C340.7,176.1,340.5,176.3,340.2,176.6z"
              />
            </g>
          </g>
          <g ref={wifiRef}>
            <g>
              <path
                className="st19"
                d="M320.8,159c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0c3.4-3.4,9.1-3.4,12.5,0c0.2,0.2,0.5,0.3,0.8,0.3
					c0.3,0,0.6-0.1,0.8-0.3c0.5-0.5,0.5-1.2,0-1.7C332.3,154.6,325.2,154.6,320.8,159z"
              />
            </g>
            <g>
              <path
                className="st19"
                d="M316.1,154.2c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0c6.1-6.1,16-6.1,22,0c0.2,0.2,0.5,0.3,0.8,0.3
					c0.3,0,0.6-0.1,0.8-0.3c0.5-0.5,0.5-1.2,0-1.7C334.4,147.3,323.1,147.3,316.1,154.2z"
              />
            </g>
            <g>
              <path
                className="st19"
                d="M328.7,142.4c-6.5,0-12.7,2.5-17.3,7.2c-0.5,0.5-0.5,1.2,0,1.7c0.5,0.5,1.2,0.5,1.7,0
					c4.2-4.2,9.7-6.5,15.7-6.5c5.9,0,11.5,2.3,15.7,6.5c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.5-0.5,0.5-1.2,0-1.7
					C341.4,145,335.3,142.4,328.7,142.4z"
              />
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default PortfolioSvg;
