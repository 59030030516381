import React, { useState } from 'react';
import Modal from 'react-modal';
import InstagramFetch from 'components/instagramFetch/instagramFetch';
import PortfolioSquareAnimation from 'components/portfolioSquareAnimation/portfolioSquareAnimation';
import { graphql, useStaticQuery } from 'gatsby';
import {
  SectionWrapper,
  ImageWrapper,
  SquareWrapper,
  StyledImage,
  StyledImageMobile,
  TextWrapper,
  TextWord,
  LinkWrapper,
  SocialImage,
  ImageLink,
  StyledModal,
  ModalImageWrapper,
  ModalImage,
  ModalButton,
} from './portfolioInstagram.styled';

const PortfolioInstagram = () => {
  Modal.setAppElement('#___gatsby');

  const instaPhotos = InstagramFetch();

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    if (window !== undefined) {
      window.location.reload();
    }
  }

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "instagram_grey.png" }) {
        sharp: childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SectionWrapper>
      <TextWrapper>
        <TextWord>Przykładowe&nbsp;</TextWord>
        <TextWord>Realizacje&nbsp;</TextWord>
      </TextWrapper>
      <ImageWrapper>
        {instaPhotos.map(photo => (
          <>
            <ImageLink onClick={openModal} key={photo.id}>
              <StyledImage fluid={photo.fluid} />
            </ImageLink>
            <StyledImageMobile fluid={photo.fluid} key={photo.id} />
          </>
        ))}
      </ImageWrapper>
      <LinkWrapper
        href="https://www.instagram.com/factoryoflook/"
        target="_blank"
      >
        <TextWord isNotVisible>Zobacz&nbsp;</TextWord>
        <TextWord isNotVisible>pozostałe&nbsp;</TextWord>
        <TextWord isNotVisible>grafiki!&nbsp;</TextWord>
        <SocialImage fluid={image.sharp.fluid} />
      </LinkWrapper>
      <SquareWrapper>
        <PortfolioSquareAnimation />
      </SquareWrapper>
      <StyledModal isOpen={modalIsOpen} onRequestClose={closeModal}>
        <ModalImageWrapper>
          {instaPhotos.map(photo => (
            <ModalImage fluid={photo.fluid} key={photo.id} />
          ))}
        </ModalImageWrapper>
        <ModalButton onClick={closeModal} type="button">
          &times;
        </ModalButton>
      </StyledModal>
    </SectionWrapper>
  );
};

export default PortfolioInstagram;
