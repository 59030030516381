import React from 'react';

import Layout from 'components/layout/layout';
import PortfolioInstagram from 'templates/portfolioSection/portfolioInstagram/portfolioInstagram';
import PortfolioBehance from 'templates/portfolioSection/portfolioBehance/portfolioBehance';
import FooterMobile from 'components/footerMobile/footerMobile';
import SEO from 'components/SEO/SEO';

const realizations = () => (
  <Layout>
    <SEO title="Realizacje" />
    <PortfolioInstagram />
    <PortfolioBehance />
    <FooterMobile />
  </Layout>
);

export default realizations;
