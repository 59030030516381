import styled from 'styled-components';

export const SquareWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Square = styled.div`
  width: 83px;
  height: 83px;
`;
